@media only screen and (min-width: 600px) and (max-width: 959px) {
    .navbar-brand {
        text-align: left;
        padding-left: 0;
    }

    .sec-home > div:nth-child(2) {
        height: auto;
        background-position-x: -160px;
        background-position-y: 0;
        padding: 160px 0;
    }
    .sec-home div:nth-child(2) h3 {
        font-size: 42px;
        line-height: 52px;
        text-align: center;
    }

    .sec-benefits .bg-inverse-white {
        background-color: transparent;
    }
    .sec-benefits .bg-inverse-transparent {
        background-color: #fff;
    }

    .sec-clinics .row-btns:last-child {
        margin-top: 18px;
    }

    .sec-rates .col-info {
        font-size: 24px;
        font-weight: 500;
    }

    .sec-hiw .col-info:not(:last-child) {
        margin-bottom: 32px;
        height: auto;
    }
    .sec-hiw .col-info > div:nth-child(1) {
        min-height: 340px;
        height: auto;
    }

    .sec-testimonials h2 {
        font-size: 46px;
    }
    .sec-testimonials .col-info:not(:last-child) {
        margin-bottom: 48px;
    }

    .packages > .col-package:not(:last-child) {
        margin-right: 0;
        margin-bottom: 24px;
    }
}

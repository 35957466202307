body {
    color: #000;
}

p {
    line-height: 1.56em;
}

.app-header {
    background-color: rgba(255, 255, 255, 1.0);
    border-bottom: none;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.7);
    border-top: 0px solid rgba(47, 46, 46, 1);
    border-bottom: 0px solid rgba(47, 46, 46, 1);
}

.app-header-no-transparent {
    background-color: rgba(255, 255, 255, 1.0);
    border-bottom: 1px solid #edf1f2;
}

.navbar-brand {
    line-height: 24px;
}
.navbar-brand img {
    max-height: 32px;
}
.navbar-brand span.tagline {
    color: #0D0D59;
    font-size: 14px;
    font-weight: 400;
}
.nav-menu>li {
    font-size: 16px;
    background-color: transparent;
    padding-right: 0;
    padding-left: 0;
}
.nav-menu>li:not(:last-child) {
    padding-right: 34px;
}
.nav-menu>li.active {
    // background-color: rgba(13, 13, 89, 1);
    // color: #fff;
    background-color: transparent;
    color: #3588CC;
}

.app-content {
    margin-top: 8px;
}

.app-footer {
    bottom: none;
}

.pagination>li>a,
.pagination>li>span {
    color: #000;
}

.pagination>.active>a,
.pagination>.active>a:focus,
.pagination>.active>a:hover,
.pagination>.active>span,
.pagination>.active>span:focus,
.pagination>.active>span:hover {
    background-color: #0052CC;
    border-color: transparent;
}

.pagination>li>a:focus,
.pagination>li>a:hover,
.pagination>li>span:focus,
.pagination>li>span:hover {
    color: #7266ba;
}

.app-header-fixed {
    padding-top: 70px;
}

a.text-dark,
.text-blue {
    color: #002E5D;
}
a.text-dark:hover,
a.text-dark:focus {
    color: #0747A6;
}

.text-d-blue {
    color: #4B4B83;
}
.text-black {
    color: #000;
}
.text-white {
    color: #fff;
}

.text-left {
    text-align: left !important;
}
.text-center {
    text-align: center !important;
}
.text-right {
    text-align: right !important;
}
.text-justify {
    text-align: justify !important;
}

.bg-gdp-blue {
    background-color: #0052CC;
}
.bg-gd-green {
    background-color: #2CB89B;
}

.input-group-addon {
    padding: 0px 12px !important;
}

.panel-heading {
    text-transform: uppercase;
    letter-spacing: 0.055em;
    font-size: 13px;
}
.panel-heading ul.list-inline {
    letter-spacing: 0.035em;
}

.panel-body .sub-header {
    text-transform: uppercase;
    letter-spacing: 0.06em;
    font-size: 12px;
}

.btn {
    font-weight: 550;
}

.badge {
    font-size: 13px;
    padding: 6px 12px;
    border-radius: 4px;
}

.bg-primary,
.btn-primary {
    background-color: #0052CC;
    border: none;
}
.btn-primary:active,
.btn-primary:hover,
.btn-primary:focus {
    background-color: #0747A6;
    border: none;
}
.btn-primary.disabled {
    background-color: rgba(64, 64, 64, 0.6);
    border: none;
}
.btn-primary.disabled:active,
.btn-primary.disabled:hover,
.btn-primary.disabled:focus {
    background-color: rgba(64, 64, 64, 0.6);
    border: none;
}

.btn-info {
    background-color: #19a9d5;
    border: none;
}
.btn-info:active,
.btn-info:hover,
.btn-info:focus {
    background-color: #5bc0de;
    border: none;
}

.btn-success {
    background-color: #27c24c;
    border: none;
}
.btn-success:active,
.btn-success:hover,
.btn-success:focus {
    background-color: #23ad44;
    border: none;
}

.btn-danger {
    background-color: #f05050;
    border: none;
}
.btn-danger:active,
.btn-danger:hover,
.btn-danger:focus {
    background-color: #ee3939;
    border: none;
}

.btn-sm,
.btn-md {
    text-transform: uppercase;
    letter-spacing: 0.03em;
}

.btn-sm {
    font-size: 12px;
    padding-top: 6px;
    padding-bottom: 6px;
}

.btn-md {
    font-size: 14px;
    padding-top: 8px;
    padding-bottom: 8px;
}

.form-control:focus {
    border: 2px solid #0052CC;
}

.i-checks input:checked + i {
    border-color: #0052CC;
}
.i-checks input:checked + i:before {
    background-color: #0052CC;
}

.alert-danger {
    background-color: #a94442;
}
.alert-success {
    background-color: #3c763d;
}

.thumb-sm {
    width: 40px;
    height: 40px;
}
.thumb-sm img {
    width: 100%;
    height: 100%;
}

.thumb-lg {
    width: 96px;
    height: 96px;
}
.thumb-lg img {
    width: 100%;
    height: 100%;
}

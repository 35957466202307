@media only screen and (min-width: 1516px) and (max-width: 1549px) {
    .sec-home > div:nth-child(1) {
        height: auto;
        background-position-x: 0;
        background-position-y: -110px;
        padding: 160px 0;
    }
}

@media only screen and (min-width: 1200px) and (max-width: 1515px) {
    .sec-home > div:nth-child(1) {
        height: auto;
        background-position-x: 0;
        background-position-y: 0;
        padding: 160px 0;
    }
}

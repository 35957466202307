/*

*/
html {
  background-color: #f0f3f4;
}
body {
  font-family: "Source Sans Pro", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 14px;
  color: #58666e;
  background-color: transparent;
  -webkit-font-smoothing: antialiased;
  line-height: 1.42857143;
}
*:focus {
  outline: 0 !important;
}
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  margin: 0;
}
a {
  color: inherit;
  text-decoration: none;
  cursor: pointer;
}
a:hover,
a:focus {
  color: inherit;
  text-decoration: none;
}
label {
  font-weight: normal;
}
small,
.small {
  font-size: 13px;
}
.badge,
.label {
  font-weight: bold;
  text-shadow: 0 1px 0 rgba(0, 0, 0, 0.2);
}
.badge.bg-light,
.label.bg-light {
  text-shadow: none;
}
.badge {
  background-color: #cfdadd;
}
.badge.up {
  position: relative;
  top: -10px;
  padding: 3px 6px;
  margin-left: -10px;
}
.badge-sm {
  font-size: 85%;
  padding: 2px 5px !important;
}
.label-sm {
  padding-top: 0;
  padding-bottom: 1px;
}
.badge-white {
  background-color: transparent;
  border: 1px solid rgba(255, 255, 255, 0.35);
  padding: 2px 6px;
}
.badge-empty {
  background-color: transparent;
  border: 1px solid rgba(0, 0, 0, 0.15);
  color: inherit;
}
blockquote {
  border-color: #dee5e7;
}
.caret-white {
  border-top-color: #fff;
  border-top-color: rgba(255, 255, 255, 0.65);
}
a:hover .caret-white {
  border-top-color: #fff;
}
.thumbnail {
  border-color: #dee5e7;
}
.progress {
  background-color: #edf1f2;
}
.progress-xxs {
  height: 2px;
}
.progress-xs {
  height: 6px;
}
.progress-sm {
  height: 12px;
}
.progress-sm .progress-bar {
  font-size: 10px;
  line-height: 1em;
}
.progress,
.progress-bar {
  -webkit-box-shadow: none;
  box-shadow: none;
}
.progress-bar-primary {
  background-color: #7266ba;
}
.progress-bar-info {
  background-color: #23b7e5;
}
.progress-bar-success {
  background-color: #27c24c;
}
.progress-bar-warning {
  background-color: #fad733;
}
.progress-bar-danger {
  background-color: #f05050;
}
.progress-bar-black {
  background-color: #1c2b36;
}
.progress-bar-white {
  background-color: #fff;
}
.accordion-group,
.accordion-inner {
  border-color: #dee5e7;
  border-radius: 2px;
}
.alert {
  font-size: 13px;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.2);
}
.alert .close i {
  font-size: 12px;
  font-weight: normal;
  display: block;
}
.form-control {
  border-color: #cfdadd;
  border-radius: 2px;
}
.form-control,
.form-control:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
}
.form-control:focus {
  border-color: #23b7e5;
}
.form-horizontal .control-label.text-left {
  text-align: left;
}
.form-control-spin {
  position: absolute;
  z-index: 2;
  right: 10px;
  top: 50%;
  margin-top: -7px;
}
.input-group-addon {
  border-color: #cfdadd;
  background-color: #edf1f2;
}
.list-group {
  border-radius: 2px;
}
.list-group.no-radius .list-group-item {
  border-radius: 0 !important;
}
.list-group.no-borders .list-group-item {
  border: none;
}
.list-group.no-border .list-group-item {
  border-width: 1px 0;
}
.list-group.no-bg .list-group-item {
  background-color: transparent;
}
.list-group-item {
  border-color: #e7ecee;
  padding-right: 15px;
}
a.list-group-item:hover,
a.list-group-item:focus,
a.list-group-item.hover {
  background-color: #f6f8f8;
}
.list-group-item.media {
  margin-top: 0;
}
.list-group-item.active {
  color: #fff;
  border-color: #23b7e5 !important;
  background-color: #23b7e5 !important;
}
.list-group-item.active .text-muted {
  color: #ace4f5 !important;
}
.list-group-item.active a {
  color: #fff;
}
.list-group-item.focus {
  background-color: #e4eaec !important;
}
.list-group-item.select {
  position: relative;
  z-index: 1;
  background-color: #dbeef9 !important;
  border-color: #c5e4f5;
}
.list-group-alt .list-group-item:nth-child(2n+2) {
  background-color: rgba(0, 0, 0, 0.02) !important;
}
.list-group-lg .list-group-item {
  padding-top: 15px;
  padding-bottom: 15px;
}
.list-group-sm .list-group-item {
  padding: 6px 10px;
}
.list-group-sp .list-group-item {
  margin-bottom: 5px;
  border-radius: 3px;
}
.list-group-item > .badge {
  margin-right: 0;
}
.list-group-item > .fa-chevron-right {
  float: right;
  margin-top: 4px;
  margin-right: -5px;
}
.list-group-item > .fa-chevron-right + .badge {
  margin-right: 5px;
}
.nav-pills.no-radius > li > a {
  border-radius: 0;
}
.nav-pills > li.active > a {
  color: #fff !important;
  background-color: #23b7e5;
}
.nav-pills > li.active > a:hover,
.nav-pills > li.active > a:active {
  background-color: #19a9d5;
}
.nav > li > a:hover,
.nav > li > a:focus {
  background-color: rgba(0, 0, 0, 0.05);
}
.nav.nav-lg > li > a {
  padding: 20px 20px;
}
.nav.nav-md > li > a {
  padding: 15px 15px;
}
.nav.nav-sm > li > a {
  padding: 6px 12px;
}
.nav.nav-xs > li > a {
  padding: 4px 10px;
}
.nav.nav-xxs > li > a {
  padding: 1px 10px;
}
.nav.nav-rounded > li > a {
  border-radius: 20px;
}
.nav .open > a,
.nav .open > a:hover,
.nav .open > a:focus {
  background-color: rgba(0, 0, 0, 0.05);
}
.nav-tabs {
  border-color: #dee5e7;
}
.nav-tabs > li > a {
  border-radius: 2px 2px 0 0;
  border-bottom-color: #dee5e7;
}
.nav-tabs > li:hover > a,
.nav-tabs > li.active > a,
.nav-tabs > li.active > a:hover {
  border-color: #dee5e7;
}
.nav-tabs > li.active > a {
  border-bottom-color: #fff !important;
}
.nav-tabs-alt .nav-tabs.nav-justified > li {
  display: table-cell;
  width: 1%;
}
.nav-tabs-alt .nav-tabs > li > a {
  border-radius: 0;
  border-color: transparent !important;
  background: transparent !important;
  border-bottom-color: #dee5e7 !important;
}
.nav-tabs-alt .nav-tabs > li.active > a {
  border-bottom-color: #23b7e5 !important;
}
.tab-container {
  margin-bottom: 15px;
}
.tab-container .tab-content {
  padding: 15px;
  background-color: #fff;
  border: 1px solid #dee5e7;
  border-top-width: 0;
  border-radius: 0 0 2px 2px;
}
.pagination > li > a {
  border-color: #dee5e7;
}
.pagination > li > a:hover,
.pagination > li > a:focus {
  border-color: #dee5e7;
  background-color: #edf1f2;
}
.panel {
  border-radius: 2px;
}
.panel .accordion-toggle {
  font-size: 14px;
  display: block;
  cursor: pointer;
}
.panel .list-group-item {
  border-color: #edf1f2;
}
.panel.no-borders {
  border-width: 0;
}
.panel.no-borders .panel-heading,
.panel.no-borders .panel-footer {
  border-width: 0;
}
.panel-heading {
  border-radius: 2px 2px 0 0;
}
.panel-default .panel-heading {
  background-color: #f6f8f8;
}
.panel-heading.no-border {
  margin: -1px -1px 0 -1px;
  border: none;
}
.panel-heading .nav {
  margin: -10px -15px;
}
.panel-heading .list-group {
  background: transparent;
}
.panel-footer {
  border-color: #edf1f2;
  border-radius: 0 0 2px 2px;
  background-color: #fff;
}
.panel-default {
  border-color: #dee5e7;
}
.panel-default > .panel-heading,
.panel-default > .panel-footer {
  border-color: #edf1f2;
}
.panel-group .panel-heading + .panel-collapse .panel-body {
  border-top: 1px solid #eaedef;
}
.table > tbody > tr > th,
.table > tfoot > tr > th,
.table > tbody > tr > td,
.table > tfoot > tr > td {
  padding: 8px 15px;
  border-top: 1px solid #eaeff0;
}
.table > thead > tr > th {
  padding: 8px 15px;
  border-bottom: 1px solid #eaeff0;
}
.table-bordered {
  border-color: #eaeff0;
}
.table-bordered > tbody > tr > td {
  border-color: #eaeff0;
}
.table-bordered > thead > tr > th {
  border-color: #eaeff0;
}
.table-striped > tbody > tr:nth-child(odd) > td,
.table-striped > tbody > tr:nth-child(odd) > th {
  background-color: #fafbfc;
}
.table-striped > thead > th {
  background-color: #fafbfc;
  border-right: 1px solid #eaeff0;
}
.table-striped > thead > th:last-child {
  border-right: none;
}
.well,
pre {
  background-color: #edf1f2;
  border-color: #dee5e7;
}
.dropdown-menu {
  border-radius: 2px;
  -webkit-box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
  border: 1px solid #dee5e7;
  border: 1px solid rgba(0, 0, 0, 0.1);
}
.dropdown-menu.pull-left {
  left: 100%;
}
.dropdown-menu > .panel {
  border: none;
  margin: -5px 0;
}
.dropdown-menu > li > a {
  padding: 5px 15px;
}
.dropdown-menu > li > a:hover,
.dropdown-menu > li > a:focus,
.dropdown-menu > .active > a,
.dropdown-menu > .active > a:hover,
.dropdown-menu > .active > a:focus {
  background-image: none;
  filter: none;
  background-color: #edf1f2 !important;
  color: #58666e;
}
.dropdown-header {
  padding: 5px 15px;
}
.dropdown-submenu {
  position: relative;
}
.dropdown-submenu:hover > a,
.dropdown-submenu:focus > a {
  background-color: #edf1f2 !important;
  color: #58666e;
}
.dropdown-submenu:hover > .dropdown-menu,
.dropdown-submenu:focus > .dropdown-menu {
  display: block;
}
.dropdown-submenu.pull-left {
  float: none !important;
}
.dropdown-submenu.pull-left > .dropdown-menu {
  left: -100%;
  margin-left: 10px;
}
.dropdown-submenu .dropdown-menu {
  left: 100%;
  top: 0;
  margin-top: -6px;
  margin-left: -1px;
}
.dropup .dropdown-submenu > .dropdown-menu {
  top: auto;
  bottom: 0;
}
.btn-group > .btn {
  margin-left: -1px;
}
/*cols*/
.col-lg-2-4 {
  position: relative;
  min-height: 1px;
  padding-left: 15px;
  padding-right: 15px;
}
.col-0 {
  clear: left;
}
.row.no-gutter {
  margin-left: 0;
  margin-right: 0;
}
.no-gutter [class*="col"] {
  padding: 0;
}
.row-sm {
  margin-left: -10px;
  margin-right: -10px;
}
.row-sm > div {
  padding-left: 10px;
  padding-right: 10px;
}
.modal-backdrop {
  background-color: #3a3f51;
}
.modal-backdrop.in {
  opacity: 0.8;
  filter: alpha(opacity=80);
}
.modal-over {
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  position: fixed;
}
.modal-center {
  position: absolute;
  left: 50%;
  top: 50%;
}
/*layout*/
html,
body {
  width: 100%;
  height: 100%;
}
body {
  overflow-x: hidden;
}
.app {
  height: auto;
  min-height: 100%;
  width: 100%;
  position: relative;
}
.app:before {
  content: "";
  position: absolute;
  width: inherit;
  top: 0;
  bottom: 0;
  z-index: -1;
  background-color: #f0f3f4;
  border: inherit;
  display: block;
}
.app-header-fixed {
  padding-top: 50px;
}
.app-header-fixed .app-header {
  position: fixed;
  top: 0;
  width: 100%;
}
.app-header {
  z-index: 1025;
  border-radius: 0;
}
.app-aside {
  float: left;
}
.app-aside:before {
  content: "";
  position: absolute;
  width: inherit;
  top: 0;
  bottom: 0;
  z-index: -1;
  background-color: inherit;
  border: inherit;
}
.app-aside-footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  z-index: 1000;
  max-width: 200px;
}
.app-aside-folded .app-aside-footer {
  max-width: 60px;
}
.app-aside-footer ~ div {
  padding-bottom: 50px;
}
.app-aside-right {
  padding-bottom: 50px;
}
.app-content {
  height: 100%;
}
.app-content:before,
.app-content:after {
  content: " ";
  display: table;
}
.app-content:after {
  clear: both;
}
.app-content-full {
  position: absolute;
  top: 50px;
  bottom: 50px;
  height: auto;
  width: auto !important;
  padding: 0 !important;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}
.app-content-full.h-full {
  bottom: 0;
  height: auto;
}
.app-content-body {
  padding-bottom: 50px;
  float: left;
  width: 100%;
}
.app-footer {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1005;
}
.app-footer.app-footer-fixed {
  position: fixed;
}
.hbox {
  display: table;
  table-layout: fixed;
  border-spacing: 0;
  width: 100%;
  height: 100%;
}
.hbox .col {
  display: table-cell;
  vertical-align: top;
  height: 100%;
  float: none;
}
.v-middle {
  vertical-align: middle !important;
}
.v-top {
  vertical-align: top !important;
}
.v-bottom {
  vertical-align: bottom !important;
}
.vbox {
  display: table;
  border-spacing: 0;
  position: relative;
  width: 100%;
  height: 100%;
  min-height: 240px;
}
.vbox .row-row {
  display: table-row;
  height: 100%;
}
.vbox .row-row .cell {
  position: relative;
  height: 100%;
  width: 100%;
}
.ie .vbox .row-row .cell {
  display: table-cell;
  overflow: auto;
}
.ie .vbox .row-row .cell .cell-inner {
  overflow: visible !important;
}
.vbox .row-row .cell .cell-inner {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  -webkit-overflow-scrolling: touch;
  overflow: auto;
}
.navbar {
  border-width: 0;
  border-radius: 0;
  margin: 0;
}
.navbar .navbar-form-sm {
  margin-top: 10px;
  margin-bottom: 10px;
}
.navbar-md {
  min-height: 60px;
}
.navbar-md .navbar-btn {
  margin-top: 13px;
}
.navbar-md .navbar-form {
  margin-top: 15px;
}
.navbar-md .navbar-nav > li > a {
  padding-top: 20px;
  padding-bottom: 20px;
}
.navbar-md .navbar-brand {
  line-height: 60px;
}
.navbar-header > button {
  text-decoration: none;
  line-height: 30px;
  font-size: 16px;
  padding: 10px 17px;
  border: none;
  background-color: transparent;
}
.navbar-brand {
  float: none !important;
  text-align: center;
  font-size: 20px;
  font-weight: 700;
  height: auto;
  line-height: 50px;
  display: inline-block;
  padding: 0 20px;
}
.navbar-brand:hover {
  text-decoration: none;
}
.navbar-brand img {
  max-height: 20px;
  margin-top: -4px;
  vertical-align: middle;
  display: inline;
}
@media (min-width: 768px) {
  .app-aside,
  .navbar-header {
    width: 200px;
  }
  .navbar-collapse,
  .app-content,
  .app-footer {
    margin-left: 200px;
  }
  .app-aside-right {
    position: absolute;
    top: 50px;
    bottom: 0;
    right: 0;
    z-index: 1000;
  }
  .app-aside-right.pos-fix {
    z-index: 1010;
  }
  .visible-folded {
    display: none;
  }
  .app-aside-folded .hidden-folded {
    display: none !important;
  }
  .app-aside-folded .visible-folded {
    display: inherit;
  }
  .app-aside-folded .text-center-folded {
    text-align: center;
  }
  .app-aside-folded .pull-none-folded {
    float: none !important;
  }
  .app-aside-folded .w-auto-folded {
    width: auto;
  }
  .app-aside-folded .app-aside,
  .app-aside-folded .navbar-header {
    width: 60px;
  }
  .app-aside-folded .navbar-collapse,
  .app-aside-folded .app-content,
  .app-aside-folded .app-footer {
    margin-left: 60px;
  }
  .app-aside-folded .app-header .navbar-brand {
    display: block;
    padding: 0;
  }
  .app-aside-fixed .app-aside:before {
    position: fixed;
  }
  .app-aside-fixed .app-header .navbar-header {
    position: fixed;
  }
  .app-aside-fixed .aside-wrap {
    position: fixed;
    overflow: hidden;
    top: 50px;
    bottom: 0;
    left: 0;
    width: 199px;
    z-index: 1000;
  }
  .app-aside-fixed .aside-wrap .navi-wrap {
    width: 217px;
    position: relative;
    height: 100%;
    overflow-x: hidden;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
  }
  .app-aside-fixed .aside-wrap .navi-wrap::-webkit-scrollbar {
    -webkit-appearance: none;
  }
  .app-aside-fixed .aside-wrap .navi-wrap::-webkit-scrollbar:vertical {
    width: 17px;
  }
  .app-aside-fixed .aside-wrap .navi-wrap > * {
    width: 200px;
  }
  .smart .app-aside-fixed .aside-wrap .navi-wrap {
    width: 200px;
  }
  .app-aside-fixed.app-aside-folded .app-aside {
    position: fixed;
    top: 0;
    bottom: 0;
    z-index: 1010;
  }
  .app-aside-fixed.app-aside-folded .aside-wrap {
    width: 59px;
  }
  .app-aside-fixed.app-aside-folded .aside-wrap .navi-wrap {
    width: 77px;
  }
  .app-aside-fixed.app-aside-folded .aside-wrap .navi-wrap > * {
    width: 60px;
  }
  .smart .app-aside-fixed.app-aside-folded .aside-wrap .navi-wrap {
    width: 60px;
  }
  .bg-auto:before {
    content: "";
    position: absolute;
    width: inherit;
    top: 0;
    bottom: 0;
    z-index: -1;
    background-color: inherit;
    border: inherit;
  }
  .bg-auto.b-l:before {
    margin-left: -1px;
  }
  .bg-auto.b-r:before {
    margin-right: -1px;
  }
  .col.show {
    display: table-cell !important;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .hbox-auto-sm {
    display: block;
  }
  .hbox-auto-sm > .col {
    width: auto;
    height: auto;
    display: block;
  }
  .hbox-auto-sm > .col.show {
    display: block !important;
  }
  .hbox-auto-sm .vbox {
    height: auto;
  }
  .hbox-auto-sm .cell-inner {
    position: static !important;
  }
}
@media (max-width: 767px) {
  body {
    height: auto;
    min-height: 100%;
  }
  .navbar-fixed-bottom {
    position: fixed;
  }
  .app-aside {
    float: none;
  }
  .app-content-full {
    position: relative;
    top: 0;
    width: 100% !important;
  }
  .hbox-auto-xs {
    display: block;
  }
  .hbox-auto-xs > .col {
    width: auto;
    height: auto;
    display: block;
  }
  .hbox-auto-xs .vbox {
    height: auto;
  }
  .hbox-auto-xs .cell-inner {
    position: static !important;
  }
  .navbar-nav {
    margin-top: 0;
    margin-bottom: 0;
  }
  .navbar-nav > li > a {
    box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.1);
  }
  .navbar-nav > li > a .up {
    top: 0;
  }
  .navbar-nav > li > a .avatar {
    width: 30px;
    margin-top: -5px;
  }
  .navbar-nav .open .dropdown-menu {
    background-color: #fff;
  }
  .navbar-form {
    box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.1);
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .navbar-form .form-group {
    margin-bottom: 0;
  }
}
html.bg {
  background: url('../img/bg.jpg');
  background-attachment: fixed;
  background-size: cover;
}
.app.container {
  padding-left: 0;
  padding-right: 0;
}
@media (min-width: 768px) {
  .app.container {
    width: 750px;
    -webkit-box-shadow: 0 0 30px rgba(0, 0, 0, 0.3);
    box-shadow: 0 0 30px rgba(0, 0, 0, 0.3);
  }
  .app.container .app-aside {
    overflow-x: hidden;
  }
  .app.container.app-aside-folded .app-aside {
    overflow-x: visible;
  }
  .app.container.app-aside-fixed .aside-wrap {
    left: inherit;
  }
  .app.container.app-aside-fixed.app-aside-folded .app-aside > ul.nav {
    position: absolute;
  }
  .app.container .app-header,
  .app.container .app-aside {
    max-width: 750px;
  }
  .app.container .app-footer-fixed {
    left: auto;
    right: auto;
    width: 100%;
    max-width: 550px;
  }
  .app.container.app-aside-folded .app-footer-fixed {
    max-width: 690px;
  }
  .app.container.app-aside-dock .app-footer-fixed {
    max-width: 750px;
  }
}
@media (min-width: 992px) {
  .app.container {
    width: 970px;
  }
  .app.container .app-header,
  .app.container .app-aside {
    max-width: 970px;
  }
  .app.container .app-footer-fixed {
    max-width: 770px;
  }
  .app.container.app-aside-folded .app-footer-fixed {
    max-width: 910px;
  }
  .app.container.app-aside-dock .app-footer-fixed {
    max-width: 970px;
  }
}
@media (min-width: 1200px) {
  .app.container {
    width: 1170px;
  }
  .app.container .app-header,
  .app.container .app-aside {
    max-width: 1170px;
  }
  .app.container .app-footer-fixed {
    max-width: 970px;
  }
  .app.container.app-aside-folded .app-footer-fixed {
    max-width: 1110px;
  }
  .app.container.app-aside-dock .app-footer-fixed {
    max-width: 1170px;
  }
}
.nav-sub {
  opacity: 0;
  height: 0;
  overflow: hidden;
  margin-left: -20px;
  -webkit-transition: all 0.2s ease-in-out 0s;
  transition: all 0.2s ease-in-out 0s;
}
.active > .nav-sub,
.app-aside-folded li:hover > .nav-sub,
.app-aside-folded li:focus > .nav-sub,
.app-aside-folded li:active > .nav-sub {
  opacity: 1;
  margin-left: 0;
  height: auto !important;
  overflow: auto;
}
.nav-sub-header {
  display: none !important;
}
.nav-sub-header a {
  padding: 15px 20px;
}
.navi ul.nav li {
  display: block;
  position: relative;
}
.navi ul.nav li li a {
  padding-left: 55px;
}
.navi ul.nav li li ul {
  display: none;
}
.navi ul.nav li li.active > ul {
  display: block;
}
.navi ul.nav li a {
  font-weight: normal;
  text-transform: none;
  display: block;
  padding: 10px 20px;
  position: relative;
  -webkit-transition: background-color 0.2s ease-in-out 0s;
  transition: background-color 0.2s ease-in-out 0s;
}
.navi ul.nav li a .badge,
.navi ul.nav li a .label {
  font-size: 11px;
  padding: 2px 5px;
  margin-top: 2px;
}
.navi ul.nav li a > i {
  margin: -10px -10px;
  line-height: 40px;
  width: 40px;
  float: left;
  margin-right: 5px;
  text-align: center;
  position: relative;
  overflow: hidden;
}
.navi ul.nav li a > i:before {
  position: relative;
  z-index: 2;
}
@media (min-width: 768px) {
  .app-aside-folded .nav-sub-header {
    display: block !important;
  }
  .app-aside-folded .nav-sub-header a {
    padding: 15px 20px !important;
  }
  .app-aside-folded .navi > ul > li > a {
    position: relative;
    padding: 0;
    text-align: center;
    height: 50px;
    border: none;
  }
  .app-aside-folded .navi > ul > li > a span {
    display: none;
  }
  .app-aside-folded .navi > ul > li > a span.pull-right {
    display: none !important;
  }
  .app-aside-folded .navi > ul > li > a i {
    width: auto;
    float: none !important;
    display: block;
    font-size: 16px;
    margin: 0;
    line-height: 50px;
    border: none !important;
  }
  .app-aside-folded .navi > ul > li > a i b {
    left: 0 !important;
  }
  .app-aside-folded .navi > ul > li > a .badge,
  .app-aside-folded .navi > ul > li > a .label {
    position: absolute;
    right: 12px;
    top: 8px;
    z-index: 3;
  }
  .app-aside-folded .navi > ul > li > ul {
    height: 0 !important;
    position: absolute;
    left: 100%;
    top: 0 !important;
    z-index: 1050;
    width: 200px;
    -webkit-box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
  }
  .app-aside-folded .navi li li a {
    padding-left: 20px !important;
  }
  .app-aside-folded.app-aside-fixed .app-aside > ul.nav {
    z-index: 1010;
    opacity: 1;
    height: auto;
    overflow: visible;
    overflow-y: auto;
    display: block;
    width: 260px;
    left: 80px;
    position: fixed;
    -webkit-overflow-scrolling: touch;
  }
  .app-aside-folded.app-aside-fixed .app-aside > ul.nav:before {
    content: "";
    width: 60px;
    height: 50px;
    position: absolute;
    left: -60px;
    top: 0;
  }
  .app-aside-folded.app-aside-fixed .app-aside > ul.nav a {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
}
@media (max-width: 767px) {
  html,
  body {
    overflow-x: hidden !important;
  }
  .app {
    overflow-x: hidden;
  }
  .app-content {
    -webkit-transition: -webkit-transform 0.2s ease;
    -moz-transition: -moz-transform 0.2s ease;
    -o-transition: -o-transform 0.2s ease;
    transition: transform 0.2s ease;
  }
  .off-screen {
    position: fixed;
    top: 50px;
    bottom: 0;
    width: 75%;
    display: block !important;
    visibility: visible;
    overflow-x: hidden;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    z-index: 1010;
  }
  .off-screen + * {
    background-color: #f0f3f4;
    -webkit-transition: -webkit-transform 0.2s ease;
    -moz-transition: -moz-transform 0.2s ease;
    -o-transition: -o-transform 0.2s ease;
    transition: transform 0.2s ease;
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-transform: translate3d(75%, 0px, 0px);
    transform: translate3d(75%, 0px, 0px);
    overflow: hidden;
    position: fixed;
    width: 100%;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1015;
    padding-top: 50px;
  }
  .off-screen + * .off-screen-toggle {
    display: block !important;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 1020;
  }
  .off-screen.pull-right {
    right: 0;
  }
  .off-screen.pull-right + * {
    -webkit-transform: translate3d(-75%, 0px, 0px);
    transform: translate3d(-75%, 0px, 0px);
  }
}
@media (min-width: 992px) {
  .app-aside-dock .app-content,
  .app-aside-dock .app-footer {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
  .app-aside-dock .app-aside-footer ~ div {
    padding-bottom: 0;
  }
  .app-aside-dock.app-aside-fixed.app-header-fixed {
    padding-top: 115px;
  }
  .app-aside-dock.app-aside-fixed .app-aside {
    position: fixed;
    top: 50px;
    width: 100%;
    z-index: 1000;
  }
  .app-aside-dock .app-aside,
  .app-aside-dock .aside-wrap,
  .app-aside-dock .navi-wrap {
    float: none;
    width: 100% !important;
    position: relative;
    top: 0;
    overflow: visible !important;
  }
  .app-aside-dock .navi-wrap > * {
    width: auto !important;
  }
  .app-aside-dock .app-aside {
    bottom: auto !important;
  }
  .app-aside-dock .app-aside.b-r {
    border-right-width: 0;
    border-bottom: 1px solid #dee5e7;
  }
  .app-aside-dock .app-aside:before {
    display: none;
  }
  .app-aside-dock .app-aside nav > .nav {
    float: left;
  }
  .app-aside-dock .app-aside .hidden-folded,
  .app-aside-dock .app-aside .line,
  .app-aside-dock .app-aside .navi-wrap > div {
    display: none !important;
  }
  .app-aside-dock .app-aside .navi > ul > li {
    position: relative;
    float: left;
    display: inline-block;
  }
  .app-aside-dock .app-aside .navi > ul > li > a {
    padding: 10px 15px 12px 15px;
    text-align: center;
    height: auto;
  }
  .app-aside-dock .app-aside .navi > ul > li > a > .badge,
  .app-aside-dock .app-aside .navi > ul > li > a > .label {
    position: absolute;
    top: 5px;
    right: 8px;
    padding: 1px 4px;
  }
  .app-aside-dock .app-aside .navi > ul > li > a > i {
    margin-left: auto;
    margin-right: auto;
    margin-bottom: -7px;
    margin-top: -10px;
    display: block;
    float: none;
    font-size: 14px;
    line-height: 40px;
    width: 40px;
  }
  .app-aside-dock .app-aside .navi > ul > li > a > span.pull-right {
    position: absolute;
    bottom: 2px;
    left: 50%;
    margin-left: -6px;
    display: block !important;
    line-height: 1;
  }
  .app-aside-dock .app-aside .navi > ul > li > a > span.pull-right i {
    line-height: 12px;
    width: 12px;
    font-size: 12px;
  }
  .app-aside-dock .app-aside .navi > ul > li > a > span.pull-right i.text {
    -webkit-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    transform: rotate(90deg);
    line-height: 14px;
  }
  .app-aside-dock .app-aside .navi > ul > li > a > span {
    font-weight: normal;
    display: block;
  }
  .app-aside-dock .app-aside .navi > ul > li .nav-sub {
    height: auto !important;
    display: none;
    position: absolute;
    left: 0;
    top: auto !important;
    z-index: 1050;
    width: 200px;
    -webkit-box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
  }
  .app-aside-dock .app-aside .navi > ul > li .nav-sub-header {
    display: none !important;
  }
  .app-aside-dock .app-aside .navi li li a {
    padding-left: 15px;
  }
  .app-aside-dock .app-aside .navi li:hover > .nav-sub,
  .app-aside-dock .app-aside .navi li:focus > .nav-sub,
  .app-aside-dock .app-aside .navi li:active > .nav-sub {
    display: block;
    opacity: 1;
    margin-left: 0;
    height: auto !important;
    overflow: auto;
  }
}
.arrow {
  border-width: 9px;
  z-index: 10;
}
.arrow,
.arrow:after {
  position: absolute;
  display: block;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
}
.arrow:after {
  border-width: 8px;
  content: "";
}
.arrow.top {
  left: 50%;
  margin-left: -9px;
  border-top-width: 0;
  border-bottom-color: rgba(0, 0, 0, 0.1);
  top: -9px;
}
.arrow.top:after {
  top: 1px;
  margin-left: -8px;
  border-top-width: 0;
  border-bottom-color: #fff;
}
.arrow.top.arrow-primary:after {
  border-bottom-color: #7266ba;
}
.arrow.top.arrow-info:after {
  border-bottom-color: #23b7e5;
}
.arrow.top.arrow-success:after {
  border-bottom-color: #27c24c;
}
.arrow.top.arrow-danger:after {
  border-bottom-color: #f05050;
}
.arrow.top.arrow-warning:after {
  border-bottom-color: #fad733;
}
.arrow.top.arrow-light:after {
  border-bottom-color: #edf1f2;
}
.arrow.top.arrow-dark:after {
  border-bottom-color: #3a3f51;
}
.arrow.top.arrow-black:after {
  border-bottom-color: #1c2b36;
}
.arrow.right {
  top: 50%;
  right: -9px;
  margin-top: -9px;
  border-right-width: 0;
  border-left-color: rgba(0, 0, 0, 0.1);
}
.arrow.right:after {
  right: 1px;
  bottom: -8px;
  border-right-width: 0;
  border-left-color: #fff;
}
.arrow.right.arrow-primary:after {
  border-left-color: #7266ba;
}
.arrow.right.arrow-info:after {
  border-left-color: #23b7e5;
}
.arrow.right.arrow-success:after {
  border-left-color: #27c24c;
}
.arrow.right.arrow-danger:after {
  border-left-color: #f05050;
}
.arrow.right.arrow-warning:after {
  border-left-color: #fad733;
}
.arrow.right.arrow-light:after {
  border-left-color: #edf1f2;
}
.arrow.right.arrow-dark:after {
  border-left-color: #3a3f51;
}
.arrow.right.arrow-black:after {
  border-left-color: #1c2b36;
}
.arrow.bottom {
  left: 50%;
  bottom: -9px;
  margin-left: -9px;
  border-bottom-width: 0;
  border-top-color: rgba(0, 0, 0, 0.1);
}
.arrow.bottom:after {
  bottom: 1px;
  margin-left: -8px;
  border-bottom-width: 0;
  border-top-color: #fff;
}
.arrow.bottom.arrow-primary:after {
  border-top-color: #7266ba;
}
.arrow.bottom.arrow-info:after {
  border-top-color: #23b7e5;
}
.arrow.bottom.arrow-success:after {
  border-top-color: #27c24c;
}
.arrow.bottom.arrow-danger:after {
  border-top-color: #f05050;
}
.arrow.bottom.arrow-warning:after {
  border-top-color: #fad733;
}
.arrow.bottom.arrow-light:after {
  border-top-color: #edf1f2;
}
.arrow.bottom.arrow-dark:after {
  border-top-color: #3a3f51;
}
.arrow.bottom.arrow-black:after {
  border-top-color: #1c2b36;
}
.arrow.left {
  top: 50%;
  left: -9px;
  margin-top: -9px;
  border-left-width: 0;
  border-right-color: rgba(0, 0, 0, 0.1);
}
.arrow.left:after {
  left: 1px;
  bottom: -8px;
  border-left-width: 0;
  border-right-color: #fff;
}
.arrow.left.arrow-primary:after {
  border-right-color: #7266ba;
}
.arrow.left.arrow-info:after {
  border-right-color: #23b7e5;
}
.arrow.left.arrow-success:after {
  border-right-color: #27c24c;
}
.arrow.left.arrow-danger:after {
  border-right-color: #f05050;
}
.arrow.left.arrow-warning:after {
  border-right-color: #fad733;
}
.arrow.left.arrow-light:after {
  border-right-color: #edf1f2;
}
.arrow.left.arrow-dark:after {
  border-right-color: #3a3f51;
}
.arrow.left.arrow-black:after {
  border-right-color: #1c2b36;
}
.arrow.pull-left {
  left: 19px;
}
.arrow.pull-right {
  left: auto;
  right: 19px;
}
.arrow.pull-up {
  top: 19px;
}
.arrow.pull-down {
  top: auto;
  bottom: 19px;
}
.btn {
  font-weight: 500;
  border-radius: 2px;
  outline: 0!important;
}
.btn-link {
  color: #58666e;
}
.btn-link.active {
  webkit-box-shadow: none;
  box-shadow: none;
}
.btn-default {
  color: #58666e !important;
  background-color: #fcfdfd;
  border-color: #dee5e7;
  background-color: #fff;
  border-bottom-color: #d8e1e3;
  -webkit-box-shadow: 0 1px 1px rgba(90, 90, 90, 0.1);
  box-shadow: 0 1px 1px rgba(90, 90, 90, 0.1);
}
.btn-default:hover,
.btn-default:focus,
.btn-default:active,
.btn-default.active,
.open .dropdown-toggle.btn-default {
  color: #58666e !important;
  background-color: #edf1f2;
  border-color: #c7d3d6;
}
.btn-default:active,
.btn-default.active,
.open .dropdown-toggle.btn-default {
  background-image: none;
}
.btn-default.disabled,
.btn-default[disabled],
fieldset[disabled] .btn-default,
.btn-default.disabled:hover,
.btn-default[disabled]:hover,
fieldset[disabled] .btn-default:hover,
.btn-default.disabled:focus,
.btn-default[disabled]:focus,
fieldset[disabled] .btn-default:focus,
.btn-default.disabled:active,
.btn-default[disabled]:active,
fieldset[disabled] .btn-default:active,
.btn-default.disabled.active,
.btn-default[disabled].active,
fieldset[disabled] .btn-default.active {
  background-color: #fcfdfd;
  border-color: #dee5e7;
}
.btn-default.btn-bg {
  border-color: rgba(0, 0, 0, 0.1);
  background-clip: padding-box;
}
.btn-primary {
  color: #fff !important;
  background-color: #7266ba;
  border-color: #7266ba;
}
.btn-primary:hover,
.btn-primary:focus,
.btn-primary:active,
.btn-primary.active,
.open .dropdown-toggle.btn-primary {
  color: #fff !important;
  background-color: #6254b2;
  border-color: #5a4daa;
}
.btn-primary:active,
.btn-primary.active,
.open .dropdown-toggle.btn-primary {
  background-image: none;
}
.btn-primary.disabled,
.btn-primary[disabled],
fieldset[disabled] .btn-primary,
.btn-primary.disabled:hover,
.btn-primary[disabled]:hover,
fieldset[disabled] .btn-primary:hover,
.btn-primary.disabled:focus,
.btn-primary[disabled]:focus,
fieldset[disabled] .btn-primary:focus,
.btn-primary.disabled:active,
.btn-primary[disabled]:active,
fieldset[disabled] .btn-primary:active,
.btn-primary.disabled.active,
.btn-primary[disabled].active,
fieldset[disabled] .btn-primary.active {
  background-color: #7266ba;
  border-color: #7266ba;
}
.btn-success {
  color: #fff !important;
  background-color: #27c24c;
  border-color: #27c24c;
}
.btn-success:hover,
.btn-success:focus,
.btn-success:active,
.btn-success.active,
.open .dropdown-toggle.btn-success {
  color: #fff !important;
  background-color: #23ad44;
  border-color: #20a03f;
}
.btn-success:active,
.btn-success.active,
.open .dropdown-toggle.btn-success {
  background-image: none;
}
.btn-success.disabled,
.btn-success[disabled],
fieldset[disabled] .btn-success,
.btn-success.disabled:hover,
.btn-success[disabled]:hover,
fieldset[disabled] .btn-success:hover,
.btn-success.disabled:focus,
.btn-success[disabled]:focus,
fieldset[disabled] .btn-success:focus,
.btn-success.disabled:active,
.btn-success[disabled]:active,
fieldset[disabled] .btn-success:active,
.btn-success.disabled.active,
.btn-success[disabled].active,
fieldset[disabled] .btn-success.active {
  background-color: #27c24c;
  border-color: #27c24c;
}
.btn-info {
  color: #fff !important;
  background-color: #23b7e5;
  border-color: #23b7e5;
}
.btn-info:hover,
.btn-info:focus,
.btn-info:active,
.btn-info.active,
.open .dropdown-toggle.btn-info {
  color: #fff !important;
  background-color: #19a9d5;
  border-color: #189ec8;
}
.btn-info:active,
.btn-info.active,
.open .dropdown-toggle.btn-info {
  background-image: none;
}
.btn-info.disabled,
.btn-info[disabled],
fieldset[disabled] .btn-info,
.btn-info.disabled:hover,
.btn-info[disabled]:hover,
fieldset[disabled] .btn-info:hover,
.btn-info.disabled:focus,
.btn-info[disabled]:focus,
fieldset[disabled] .btn-info:focus,
.btn-info.disabled:active,
.btn-info[disabled]:active,
fieldset[disabled] .btn-info:active,
.btn-info.disabled.active,
.btn-info[disabled].active,
fieldset[disabled] .btn-info.active {
  background-color: #23b7e5;
  border-color: #23b7e5;
}
.btn-warning {
  color: #fff !important;
  background-color: #fad733;
  border-color: #fad733;
}
.btn-warning:hover,
.btn-warning:focus,
.btn-warning:active,
.btn-warning.active,
.open .dropdown-toggle.btn-warning {
  color: #fff !important;
  background-color: #f9d21a;
  border-color: #f9cf0b;
}
.btn-warning:active,
.btn-warning.active,
.open .dropdown-toggle.btn-warning {
  background-image: none;
}
.btn-warning.disabled,
.btn-warning[disabled],
fieldset[disabled] .btn-warning,
.btn-warning.disabled:hover,
.btn-warning[disabled]:hover,
fieldset[disabled] .btn-warning:hover,
.btn-warning.disabled:focus,
.btn-warning[disabled]:focus,
fieldset[disabled] .btn-warning:focus,
.btn-warning.disabled:active,
.btn-warning[disabled]:active,
fieldset[disabled] .btn-warning:active,
.btn-warning.disabled.active,
.btn-warning[disabled].active,
fieldset[disabled] .btn-warning.active {
  background-color: #fad733;
  border-color: #fad733;
}
.btn-danger {
  color: #fff !important;
  background-color: #f05050;
  border-color: #f05050;
}
.btn-danger:hover,
.btn-danger:focus,
.btn-danger:active,
.btn-danger.active,
.open .dropdown-toggle.btn-danger {
  color: #fff !important;
  background-color: #ee3939;
  border-color: #ed2a2a;
}
.btn-danger:active,
.btn-danger.active,
.open .dropdown-toggle.btn-danger {
  background-image: none;
}
.btn-danger.disabled,
.btn-danger[disabled],
fieldset[disabled] .btn-danger,
.btn-danger.disabled:hover,
.btn-danger[disabled]:hover,
fieldset[disabled] .btn-danger:hover,
.btn-danger.disabled:focus,
.btn-danger[disabled]:focus,
fieldset[disabled] .btn-danger:focus,
.btn-danger.disabled:active,
.btn-danger[disabled]:active,
fieldset[disabled] .btn-danger:active,
.btn-danger.disabled.active,
.btn-danger[disabled].active,
fieldset[disabled] .btn-danger.active {
  background-color: #f05050;
  border-color: #f05050;
}
.btn-dark {
  color: #fff !important;
  background-color: #3a3f51;
  border-color: #3a3f51;
}
.btn-dark:hover,
.btn-dark:focus,
.btn-dark:active,
.btn-dark.active,
.open .dropdown-toggle.btn-dark {
  color: #fff !important;
  background-color: #2f3342;
  border-color: #292d39;
}
.btn-dark:active,
.btn-dark.active,
.open .dropdown-toggle.btn-dark {
  background-image: none;
}
.btn-dark.disabled,
.btn-dark[disabled],
fieldset[disabled] .btn-dark,
.btn-dark.disabled:hover,
.btn-dark[disabled]:hover,
fieldset[disabled] .btn-dark:hover,
.btn-dark.disabled:focus,
.btn-dark[disabled]:focus,
fieldset[disabled] .btn-dark:focus,
.btn-dark.disabled:active,
.btn-dark[disabled]:active,
fieldset[disabled] .btn-dark:active,
.btn-dark.disabled.active,
.btn-dark[disabled].active,
fieldset[disabled] .btn-dark.active {
  background-color: #3a3f51;
  border-color: #3a3f51;
}
.btn-black {
  color: #fff !important;
  background-color: #1c2b36;
  border-color: #1c2b36;
}
.btn-black:hover,
.btn-black:focus,
.btn-black:active,
.btn-black.active,
.open .dropdown-toggle.btn-black {
  color: #fff !important;
  background-color: #131e25;
  border-color: #0e161b;
}
.btn-black:active,
.btn-black.active,
.open .dropdown-toggle.btn-black {
  background-image: none;
}
.btn-black.disabled,
.btn-black[disabled],
fieldset[disabled] .btn-black,
.btn-black.disabled:hover,
.btn-black[disabled]:hover,
fieldset[disabled] .btn-black:hover,
.btn-black.disabled:focus,
.btn-black[disabled]:focus,
fieldset[disabled] .btn-black:focus,
.btn-black.disabled:active,
.btn-black[disabled]:active,
fieldset[disabled] .btn-black:active,
.btn-black.disabled.active,
.btn-black[disabled].active,
fieldset[disabled] .btn-black.active {
  background-color: #1c2b36;
  border-color: #1c2b36;
}
.btn-icon {
  padding: 0 !important;
  text-align: center;
  width: 34px;
  height: 34px;
}
.btn-icon i {
  top: -1px;
  position: relative;
  line-height: 34px;
}
.btn-icon.btn-sm {
  width: 30px;
  height: 30px;
}
.btn-icon.btn-sm i {
  line-height: 30px;
}
.btn-icon.btn-lg {
  width: 45px;
  height: 45px;
}
.btn-icon.btn-lg i {
  line-height: 45px;
}
.btn-rounded {
  border-radius: 50px;
  padding-left: 15px;
  padding-right: 15px;
}
.btn-rounded.btn-lg {
  padding-left: 25px;
  padding-right: 25px;
}
.btn > i.pull-left,
.btn > i.pull-right {
  line-height: 1.42857143;
}
.btn-block {
  padding-left: 12px;
  padding-right: 12px;
}
.btn-group-vertical > .btn:first-child:not(:last-child) {
  border-top-right-radius: 2px;
}
.btn-group-vertical > .btn:last-child:not(:first-child) {
  border-bottom-left-radius: 2px;
}
.btn-addon i {
  margin: -7px -12px;
  margin-right: 12px;
  background-color: rgba(0, 0, 0, 0.1);
  width: 34px;
  height: 34px;
  line-height: 34px;
  text-align: center;
  float: left;
  position: relative;
  border-radius: 2px 0 0 2px;
}
.btn-addon i.pull-right {
  margin-right: -12px;
  margin-left: 12px;
  border-radius: 0 2px 2px 0;
}
.btn-addon.btn-sm i {
  margin: -6px -10px;
  margin-right: 10px;
  width: 30px;
  height: 30px;
  line-height: 30px;
}
.btn-addon.btn-sm i.pull-right {
  margin-right: -10px;
  margin-left: 10px;
}
.btn-addon.btn-lg i {
  margin: -11px -16px;
  margin-right: 16px;
  width: 45px;
  height: 45px;
  line-height: 45px;
}
.btn-addon.btn-lg i.pull-right {
  margin-right: -16px;
  margin-left: 16px;
}
.btn-addon.btn-default i {
  background-color: transparent;
  border-right: 1px solid #dee5e7;
}
.btn-groups .btn {
  margin-bottom: 5px;
}
.list-icon i {
  width: 40px;
  vertical-align: middle;
  margin: 0;
  display: inline-block;
  text-align: center;
  -webkit-transition: font-size 0.2s;
  transition: font-size 0.2s;
}
.list-icon div {
  line-height: 40px;
  white-space: nowrap;
}
.list-icon div:hover i {
  font-size: 26px;
}
.settings {
  z-index: 1050;
  position: fixed;
  top: 120px;
  right: -240px;
  width: 240px;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
}
.settings.active {
  right: -1px;
}
.settings > .btn {
  background: #f6f8f8 !important;
  border-right-width: 0;
  border-color: #dee5e7;
  position: absolute;
  left: -42px;
  top: -1px;
  padding: 10px 15px;
}
.settings .i-checks span b {
  width: 50%;
  height: 20px;
  display: inline-block;
  float: left;
}
.settings .i-checks span b.header {
  height: 10px;
}
.streamline {
  position: relative;
  border-color: #dee5e7;
}
.streamline .sl-item:after,
.streamline:after {
  content: '';
  position: absolute;
  background-color: #fff;
  border-color: inherit;
  border-width: 1px;
  border-style: solid;
  border-radius: 10px;
  width: 9px;
  height: 9px;
  margin-left: -5px;
  bottom: 0;
  left: 0;
}
.sl-item {
  border-color: #dee5e7;
  position: relative;
  padding-bottom: 1px;
}
.sl-item:before,
.sl-item:after {
  content: " ";
  display: table;
}
.sl-item:after {
  clear: both;
}
.sl-item:after {
  top: 6px;
  bottom: auto;
}
.sl-item.b-l {
  margin-left: -1px;
}
.timeline {
  margin: 0;
  padding: 0;
}
.tl-item {
  display: block;
}
.tl-item:before,
.tl-item:after {
  content: " ";
  display: table;
}
.tl-item:after {
  clear: both;
}
.visible-left {
  display: none;
}
.tl-wrap {
  display: block;
  margin-left: 6em;
  padding: 15px 0 15px 20px;
  border-style: solid;
  border-color: #dee5e7;
  border-width: 0 0 0 4px;
}
.tl-wrap:before,
.tl-wrap:after {
  content: " ";
  display: table;
}
.tl-wrap:after {
  clear: both;
}
.tl-wrap:before {
  position: relative;
  content: "";
  float: left;
  top: 15px;
  margin-left: -27px;
  width: 10px;
  height: 10px;
  border-color: inherit;
  border-width: 3px;
  border-radius: 50%;
  border-style: solid;
  background: #edf1f2;
  box-shadow: 0 0 0 4px #f0f3f4;
}
.tl-wrap:hover:before {
  background: transparent;
  border-color: #fff;
}
.tl-date {
  position: relative;
  top: 10px;
  float: left;
  margin-left: -7.5em;
  display: block;
  width: 4.5em;
  text-align: right;
}
.tl-content {
  display: inline-block;
  position: relative;
  padding-top: 10px;
  padding-bottom: 10px;
}
.tl-content.block {
  display: block;
  width: 100%;
}
.tl-content.panel {
  margin-bottom: 0;
}
.tl-header {
  display: block;
  width: 12em;
  text-align: center;
  margin-left: 2px;
  margin-right: 2px;
}
.timeline-center .tl-item {
  margin-left: 50%;
}
.timeline-center .tl-item .tl-wrap {
  margin-left: -2px;
}
.timeline-center .tl-header {
  width: auto;
  margin: 0;
}
.timeline-center .tl-left {
  margin-left: 0;
  margin-right: 50%;
}
.timeline-center .tl-left .hidden-left {
  display: none !important;
}
.timeline-center .tl-left .visible-left {
  display: inherit;
}
.timeline-center .tl-left .tl-wrap {
  float: right;
  margin-right: -2px;
  border-left-width: 0;
  border-right-width: 4px;
  padding-left: 0;
  padding-right: 20px;
}
.timeline-center .tl-left .tl-wrap:before {
  float: right;
  margin-left: 0;
  margin-right: -27px;
}
.timeline-center .tl-left .tl-date {
  float: right;
  margin-left: 0;
  margin-right: -8.5em;
  text-align: left;
}
.i-switch {
  cursor: pointer;
  position: relative;
  display: inline-block;
  width: 35px;
  height: 20px;
  border-radius: 30px;
  background-color: #27c24c;
  margin: 0;
}
.i-switch input {
  position: absolute;
  opacity: 0;
  filter: alpha(opacity=0);
}
.i-switch input:checked + i:before {
  top: 50%;
  bottom: 50%;
  left: 50%;
  right: 5px;
  border-width: 0;
  border-radius: 5px;
}
.i-switch input:checked + i:after {
  margin-left: 16px;
}
.i-switch i:before {
  content: "";
  position: absolute;
  top: -1px;
  bottom: -1px;
  left: -1px;
  right: -1px;
  background-color: #fff;
  border: 1px solid #f0f0f0;
  border-radius: 30px;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
}
.i-switch i:after {
  content: "";
  position: absolute;
  background-color: #fff;
  width: 18px;
  top: 1px;
  bottom: 1px;
  border-radius: 50%;
  -webkit-box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.25);
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.25);
  -webkit-transition: margin-left 0.3s;
  transition: margin-left 0.3s;
}
.i-switch-md {
  width: 40px;
  height: 24px;
}
.i-switch-md input:checked + i:after {
  margin-left: 17px;
}
.i-switch-md i:after {
  width: 22px;
}
.i-switch-lg {
  width: 50px;
  height: 30px;
}
.i-switch-lg input:checked + i:after {
  margin-left: 21px;
}
.i-switch-lg i:after {
  width: 28px;
}
.i-checks {
  padding-left: 20px;
  cursor: pointer;
}
.i-checks input {
  opacity: 0;
  position: absolute;
  margin-left: -20px;
}
.i-checks input:checked + i {
  border-color: #23b7e5;
}
.i-checks input:checked + i:before {
  left: 4px;
  top: 4px;
  width: 10px;
  height: 10px;
  background-color: #23b7e5;
}
.i-checks input:checked + span .active {
  display: inherit;
}
.i-checks input[type="radio"] + i,
.i-checks input[type="radio"] + i:before {
  border-radius: 50%;
}
.i-checks input[disabled] + i,
fieldset[disabled] .i-checks input + i {
  border-color: #dee5e7;
}
.i-checks input[disabled] + i:before,
fieldset[disabled] .i-checks input + i:before {
  background-color: #dee5e7;
}
.i-checks > i {
  width: 20px;
  height: 20px;
  line-height: 1;
  border: 1px solid #cfdadd;
  background-color: #fff;
  margin-left: -20px;
  margin-top: -2px;
  display: inline-block;
  vertical-align: middle;
  margin-right: 4px;
  position: relative;
}
.i-checks > i:before {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  width: 0px;
  height: 0px;
  background-color: transparent;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
}
.i-checks > span {
  margin-left: -20px;
}
.i-checks > span .active {
  display: none;
}
.i-checks-sm input:checked + i:before {
  left: 3px;
  top: 3px;
  width: 8px;
  height: 8px;
}
.i-checks-sm > i {
  width: 16px;
  height: 16px;
  margin-left: -18px;
  margin-right: 6px;
}
.i-checks-lg input:checked + i:before {
  left: 8px;
  top: 8px;
  width: 12px;
  height: 12px;
}
.i-checks-lg > i {
  width: 30px;
  height: 30px;
}
.datepicker {
  margin: 0 5px;
}
.datepicker .btn-default {
  border-width: 0;
  box-shadow: none;
}
.datepicker .btn[disabled] {
  opacity: 0.4;
}
.datepicker .btn-info .text-info {
  color: #fff !important;
}
/*Charts*/
.jqstooltip {
  background-color: rgba(0, 0, 0, 0.8) !important;
  border: solid 1px #000 !important;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  padding: 5px 10px !important;
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
  max-height: 12px;
}
.easyPieChart {
  position: relative;
  text-align: center;
}
.easyPieChart > div {
  position: relative;
  z-index: 1;
}
.easyPieChart > div .text {
  position: absolute;
  width: 100%;
  top: 60%;
  line-height: 1;
}
.easyPieChart > div img {
  margin-top: -4px;
}
.easyPieChart canvas {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
}
#flotTip,
.flotTip {
  padding: 4px 10px;
  background-color: rgba(0, 0, 0, 0.8);
  border: solid 1px #000 !important;
  z-index: 100;
  font-size: 12px;
  color: #fff;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
}
.legendColorBox > div {
  border: none !important;
  margin: 5px;
}
.legendColorBox > div > div {
  border-radius: 10px;
}
.sortable-placeholder {
  list-style: none;
  border: 1px dashed #CCC;
  min-height: 50px;
  margin-bottom: 5px;
}
.panel .dataTables_wrapper {
  padding-top: 10px;
}
.panel .dataTables_wrapper > .row {
  margin: 0;
}
.panel .dataTables_wrapper > .row > .col-sm-12 {
  padding: 0;
}
.st-sort-ascent:before {
  content: '\25B2';
}
.st-sort-descent:before {
  content: '\25BC';
}
.st-selected td {
  background: #f0f9ec !important;
}
.chosen-choices,
.chosen-single,
.bootstrap-tagsinput {
  border-radius: 2px !important;
  border-color: #cfdadd !important;
}
.bootstrap-tagsinput {
  padding: 5px 12px !important;
}
.item {
  position: relative;
}
.item .top {
  position: absolute;
  top: 0;
  left: 0;
}
.item .bottom {
  position: absolute;
  bottom: 0;
  left: 0;
}
.item .center {
  position: absolute;
  top: 50%;
}
.item-overlay {
  display: none;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.item-overlay.active,
.item:hover .item-overlay {
  display: block;
}
.form-validation .form-control.ng-dirty.ng-invalid {
  border-color: #f05050;
}
.form-validation .form-control.ng-dirty.ng-valid,
.form-validation .form-control.ng-dirty.ng-valid:focus {
  border-color: #27c24c;
}
.form-validation .i-checks .ng-invalid.ng-dirty + i {
  border-color: #f05050;
}
.ng-animate .bg-auto:before {
  display: none;
}
[ui-view].ng-leave {
  display: none;
}
[ui-view].ng-leave.smooth {
  display: block;
}
.smooth.ng-animate {
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.fade-in-right-big.ng-enter {
  -webkit-animation: fadeInRightBig 0.5s;
  animation: fadeInRightBig 0.5s;
}
.fade-in-right-big.ng-leave {
  -webkit-animation: fadeOutLeftBig 0.5s;
  animation: fadeOutLeftBig 0.5s;
}
.fade-in-left-big.ng-enter {
  -webkit-animation: fadeInLeftBig 0.5s;
  animation: fadeInLeftBig 0.5s;
}
.fade-in-left-big.ng-leave {
  -webkit-animation: fadeOutRightBig 0.5s;
  animation: fadeOutRightBig 0.5s;
}
.fade-in-up-big.ng-enter {
  -webkit-animation: fadeInUpBig 0.5s;
  animation: fadeInUpBig 0.5s;
}
.fade-in-up-big.ng-leave {
  -webkit-animation: fadeOutUpBig 0.5s;
  animation: fadeOutUpBig 0.5s;
}
.fade-in-down-big.ng-enter {
  -webkit-animation: fadeInDownBig 0.5s;
  animation: fadeInDownBig 0.5s;
}
.fade-in-down-big.ng-leave {
  -webkit-animation: fadeOutDownBig 0.5s;
  animation: fadeOutDownBig 0.5s;
}
.fade-in.ng-enter {
  -webkit-animation: fadeIn 0.5s;
  animation: fadeIn 0.5s;
}
.fade-in.ng-leave {
  -webkit-animation: fadeOut 0.5s;
  animation: fadeOut 0.5s;
}
.fade-in-right.ng-enter {
  -webkit-animation: fadeInRight 0.5s;
  animation: fadeInRight 0.5s;
}
.fade-in-right.ng-leave {
  -webkit-animation: fadeOutLeft 0.5s;
  animation: fadeOutLeft 0.5s;
}
.fade-in-left.ng-enter {
  -webkit-animation: fadeInLeft 0.5s;
  animation: fadeInLeft 0.5s;
}
.fade-in-left.ng-leave {
  -webkit-animation: fadeOutRight 0.5s;
  animation: fadeOutRight 0.5s;
}
.fade-in-up.ng-enter {
  -webkit-animation: fadeInUp 0.5s;
  animation: fadeInUp 0.5s;
}
.fade-in-up.ng-leave {
  -webkit-animation: fadeOutUp 0.5s;
  animation: fadeOutUp 0.5s;
}
.fade-in-down.ng-enter {
  -webkit-animation: fadeInDown 0.5s;
  animation: fadeInDown 0.5s;
}
.fade-in-down.ng-leave {
  -webkit-animation: fadeOutDown 0.5s;
  animation: fadeOutDown 0.5s;
}
.bg-gd {
  background-image: -webkit-linear-gradient(top, rgba(40, 50, 60, 0) 0, rgba(40, 50, 60, 0.075) 100%);
  background-image: -o-linear-gradient(top, rgba(40, 50, 60, 0) 0, rgba(40, 50, 60, 0.075) 100%);
  background-image: linear-gradient(to bottom, rgba(40, 50, 60, 0) 0, rgba(40, 50, 60, 0.075) 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#0028323c', endColorstr='#1328323c', GradientType=0);
  filter: none;
}
.bg-gd-dk {
  background-image: -webkit-linear-gradient(top, rgba(40, 50, 60, 0) 10%, rgba(40, 50, 60, 0.5) 100%);
  background-image: -o-linear-gradient(top, rgba(40, 50, 60, 0) 10%, rgba(40, 50, 60, 0.5) 100%);
  background-image: linear-gradient(to bottom, rgba(40, 50, 60, 0) 10%, rgba(40, 50, 60, 0.5) 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#0028323c', endColorstr='#8028323c', GradientType=0);
  filter: none;
}
.bg-light {
  background-color: #edf1f2;
  color: #58666e;
}
.bg-light.lt,
.bg-light .lt {
  background-color: #f3f5f6;
}
.bg-light.lter,
.bg-light .lter {
  background-color: #f6f8f8;
}
.bg-light.dk,
.bg-light .dk {
  background-color: #e4eaec;
}
.bg-light.dker,
.bg-light .dker {
  background-color: #dde6e9;
}
.bg-light.bg,
.bg-light .bg {
  background-color: #edf1f2;
}
.bg-dark {
  background-color: #3a3f51;
  color: #a6a8b1;
}
.bg-dark.lt,
.bg-dark .lt {
  background-color: #474c5e;
}
.bg-dark.lter,
.bg-dark .lter {
  background-color: #54596a;
}
.bg-dark.dk,
.bg-dark .dk {
  background-color: #2e3344;
}
.bg-dark.dker,
.bg-dark .dker {
  background-color: #232735;
}
.bg-dark.bg,
.bg-dark .bg {
  background-color: #3a3f51;
}
.bg-dark a {
  color: #c1c3c9;
}
.bg-dark a:hover {
  color: #fff;
}
.bg-dark a.list-group-item:hover,
.bg-dark a.list-group-item:focus {
  background-color: inherit;
}
.bg-dark .nav > li:hover > a,
.bg-dark .nav > li:focus > a,
.bg-dark .nav > li.active > a {
  color: #fff;
  background-color: #2e3344;
}
.bg-dark .nav > li > a {
  color: #b4b6bd;
}
.bg-dark .nav > li > a:hover,
.bg-dark .nav > li > a:focus {
  background-color: #32374a;
}
.bg-dark .nav .open > a {
  background-color: #2e3344;
}
.bg-dark .caret {
  border-top-color: #a6a8b1;
  border-bottom-color: #a6a8b1;
}
.bg-dark.navbar .nav > li.active > a {
  color: #fff;
  background-color: #2e3344;
}
.bg-dark .open > a,
.bg-dark .open > a:hover,
.bg-dark .open > a:focus {
  color: #fff;
}
.bg-dark .text-muted {
  color: #8b8e99 !important;
}
.bg-dark .text-lt {
  color: #eaebed !important;
}
.bg-dark.auto .list-group-item,
.bg-dark .auto .list-group-item {
  border-color: #2f3342 !important;
  background-color: transparent;
}
.bg-dark.auto .list-group-item:hover,
.bg-dark .auto .list-group-item:hover,
.bg-dark.auto .list-group-item:focus,
.bg-dark .auto .list-group-item:focus,
.bg-dark.auto .list-group-item:active,
.bg-dark .auto .list-group-item:active,
.bg-dark.auto .list-group-item.active,
.bg-dark .auto .list-group-item.active {
  background-color: #2e3344 !important;
}
.bg-black {
  background-color: #1c2b36;
  color: #7793a7;
}
.bg-black.lt,
.bg-black .lt {
  background-color: #263845;
}
.bg-black.lter,
.bg-black .lter {
  background-color: #314554;
}
.bg-black.dk,
.bg-black .dk {
  background-color: #131e26;
}
.bg-black.dker,
.bg-black .dker {
  background-color: #0a1015;
}
.bg-black.bg,
.bg-black .bg {
  background-color: #1c2b36;
}
.bg-black a {
  color: #96abbb;
}
.bg-black a:hover {
  color: #fff;
}
.bg-black a.list-group-item:hover,
.bg-black a.list-group-item:focus {
  background-color: inherit;
}
.bg-black .nav > li:hover > a,
.bg-black .nav > li:focus > a,
.bg-black .nav > li.active > a {
  color: #fff;
  background-color: #131e26;
}
.bg-black .nav > li > a {
  color: #869fb1;
}
.bg-black .nav > li > a:hover,
.bg-black .nav > li > a:focus {
  background-color: #16232d;
}
.bg-black .nav .open > a {
  background-color: #131e26;
}
.bg-black .caret {
  border-top-color: #7793a7;
  border-bottom-color: #7793a7;
}
.bg-black.navbar .nav > li.active > a {
  color: #fff;
  background-color: #131e26;
}
.bg-black .open > a,
.bg-black .open > a:hover,
.bg-black .open > a:focus {
  color: #fff;
}
.bg-black .text-muted {
  color: #5c798f !important;
}
.bg-black .text-lt {
  color: #c4d0d9 !important;
}
.bg-black.auto .list-group-item,
.bg-black .auto .list-group-item {
  border-color: #131e25 !important;
  background-color: transparent;
}
.bg-black.auto .list-group-item:hover,
.bg-black .auto .list-group-item:hover,
.bg-black.auto .list-group-item:focus,
.bg-black .auto .list-group-item:focus,
.bg-black.auto .list-group-item:active,
.bg-black .auto .list-group-item:active,
.bg-black.auto .list-group-item.active,
.bg-black .auto .list-group-item.active {
  background-color: #131e26 !important;
}
.bg-primary {
  background-color: #7266ba;
  color: #f4f3f9;
}
.bg-primary.lt,
.bg-primary .lt {
  background-color: #847abf;
}
.bg-primary.lter,
.bg-primary .lter {
  background-color: #958dc6;
}
.bg-primary.dk,
.bg-primary .dk {
  background-color: #6051b5;
}
.bg-primary.dker,
.bg-primary .dker {
  background-color: #5244a9;
}
.bg-primary.bg,
.bg-primary .bg {
  background-color: #7266ba;
}
.bg-primary a {
  color: #ffffff;
}
.bg-primary a:hover {
  color: #fff;
}
.bg-primary a.list-group-item:hover,
.bg-primary a.list-group-item:focus {
  background-color: inherit;
}
.bg-primary .nav > li:hover > a,
.bg-primary .nav > li:focus > a,
.bg-primary .nav > li.active > a {
  color: #fff;
  background-color: #6051b5;
}
.bg-primary .nav > li > a {
  color: #f2f2f2;
}
.bg-primary .nav > li > a:hover,
.bg-primary .nav > li > a:focus {
  background-color: #6658b8;
}
.bg-primary .nav .open > a {
  background-color: #6051b5;
}
.bg-primary .caret {
  border-top-color: #f4f3f9;
  border-bottom-color: #f4f3f9;
}
.bg-primary.navbar .nav > li.active > a {
  color: #fff;
  background-color: #6051b5;
}
.bg-primary .open > a,
.bg-primary .open > a:hover,
.bg-primary .open > a:focus {
  color: #fff;
}
.bg-primary .text-muted {
  color: #d6d3e6 !important;
}
.bg-primary .text-lt {
  color: #ffffff !important;
}
.bg-primary.auto .list-group-item,
.bg-primary .auto .list-group-item {
  border-color: #6254b2 !important;
  background-color: transparent;
}
.bg-primary.auto .list-group-item:hover,
.bg-primary .auto .list-group-item:hover,
.bg-primary.auto .list-group-item:focus,
.bg-primary .auto .list-group-item:focus,
.bg-primary.auto .list-group-item:active,
.bg-primary .auto .list-group-item:active,
.bg-primary.auto .list-group-item.active,
.bg-primary .auto .list-group-item.active {
  background-color: #6051b5 !important;
}
.bg-success {
  background-color: #27c24c;
  color: #c6efd0;
}
.bg-success.lt,
.bg-success .lt {
  background-color: #31d257;
}
.bg-success.lter,
.bg-success .lter {
  background-color: #48d46a;
}
.bg-success.dk,
.bg-success .dk {
  background-color: #20af42;
}
.bg-success.dker,
.bg-success .dker {
  background-color: #1a9c39;
}
.bg-success.bg,
.bg-success .bg {
  background-color: #27c24c;
}
.bg-success a {
  color: #eefaf1;
}
.bg-success a:hover {
  color: #fff;
}
.bg-success a.list-group-item:hover,
.bg-success a.list-group-item:focus {
  background-color: inherit;
}
.bg-success .nav > li:hover > a,
.bg-success .nav > li:focus > a,
.bg-success .nav > li.active > a {
  color: #fff;
  background-color: #20af42;
}
.bg-success .nav > li > a {
  color: #daf5e0;
}
.bg-success .nav > li > a:hover,
.bg-success .nav > li > a:focus {
  background-color: #22b846;
}
.bg-success .nav .open > a {
  background-color: #20af42;
}
.bg-success .caret {
  border-top-color: #c6efd0;
  border-bottom-color: #c6efd0;
}
.bg-success.navbar .nav > li.active > a {
  color: #fff;
  background-color: #20af42;
}
.bg-success .open > a,
.bg-success .open > a:hover,
.bg-success .open > a:focus {
  color: #fff;
}
.bg-success .text-muted {
  color: #9ee4af !important;
}
.bg-success .text-lt {
  color: #ffffff !important;
}
.bg-success.auto .list-group-item,
.bg-success .auto .list-group-item {
  border-color: #23ad44 !important;
  background-color: transparent;
}
.bg-success.auto .list-group-item:hover,
.bg-success .auto .list-group-item:hover,
.bg-success.auto .list-group-item:focus,
.bg-success .auto .list-group-item:focus,
.bg-success.auto .list-group-item:active,
.bg-success .auto .list-group-item:active,
.bg-success.auto .list-group-item.active,
.bg-success .auto .list-group-item.active {
  background-color: #20af42 !important;
}
.bg-info {
  background-color: #23b7e5;
  color: #dcf2f8;
}
.bg-info.lt,
.bg-info .lt {
  background-color: #3dbde5;
}
.bg-info.lter,
.bg-info .lter {
  background-color: #55c3e6;
}
.bg-info.dk,
.bg-info .dk {
  background-color: #16aad8;
}
.bg-info.dker,
.bg-info .dker {
  background-color: #1199c4;
}
.bg-info.bg,
.bg-info .bg {
  background-color: #23b7e5;
}
.bg-info a {
  color: #ffffff;
}
.bg-info a:hover {
  color: #fff;
}
.bg-info a.list-group-item:hover,
.bg-info a.list-group-item:focus {
  background-color: inherit;
}
.bg-info .nav > li:hover > a,
.bg-info .nav > li:focus > a,
.bg-info .nav > li.active > a {
  color: #fff;
  background-color: #16aad8;
}
.bg-info .nav > li > a {
  color: #f2f2f2;
}
.bg-info .nav > li > a:hover,
.bg-info .nav > li > a:focus {
  background-color: #17b2e2;
}
.bg-info .nav .open > a {
  background-color: #16aad8;
}
.bg-info .caret {
  border-top-color: #dcf2f8;
  border-bottom-color: #dcf2f8;
}
.bg-info.navbar .nav > li.active > a {
  color: #fff;
  background-color: #16aad8;
}
.bg-info .open > a,
.bg-info .open > a:hover,
.bg-info .open > a:focus {
  color: #fff;
}
.bg-info .text-muted {
  color: #b0e1f1 !important;
}
.bg-info .text-lt {
  color: #ffffff !important;
}
.bg-info.auto .list-group-item,
.bg-info .auto .list-group-item {
  border-color: #19a9d5 !important;
  background-color: transparent;
}
.bg-info.auto .list-group-item:hover,
.bg-info .auto .list-group-item:hover,
.bg-info.auto .list-group-item:focus,
.bg-info .auto .list-group-item:focus,
.bg-info.auto .list-group-item:active,
.bg-info .auto .list-group-item:active,
.bg-info.auto .list-group-item.active,
.bg-info .auto .list-group-item.active {
  background-color: #16aad8 !important;
}
.bg-warning {
  background-color: #fad733;
  color: #fffefa;
}
.bg-warning.lt,
.bg-warning .lt {
  background-color: #f8da4e;
}
.bg-warning.lter,
.bg-warning .lter {
  background-color: #f7de69;
}
.bg-warning.dk,
.bg-warning .dk {
  background-color: #fcd417;
}
.bg-warning.dker,
.bg-warning .dker {
  background-color: #face00;
}
.bg-warning.bg,
.bg-warning .bg {
  background-color: #fad733;
}
.bg-warning a {
  color: #ffffff;
}
.bg-warning a:hover {
  color: #fff;
}
.bg-warning a.list-group-item:hover,
.bg-warning a.list-group-item:focus {
  background-color: inherit;
}
.bg-warning .nav > li:hover > a,
.bg-warning .nav > li:focus > a,
.bg-warning .nav > li.active > a {
  color: #fff;
  background-color: #fcd417;
}
.bg-warning .nav > li > a {
  color: #f2f2f2;
}
.bg-warning .nav > li > a:hover,
.bg-warning .nav > li > a:focus {
  background-color: #fcd621;
}
.bg-warning .nav .open > a {
  background-color: #fcd417;
}
.bg-warning .caret {
  border-top-color: #fffefa;
  border-bottom-color: #fffefa;
}
.bg-warning.navbar .nav > li.active > a {
  color: #fff;
  background-color: #fcd417;
}
.bg-warning .open > a,
.bg-warning .open > a:hover,
.bg-warning .open > a:focus {
  color: #fff;
}
.bg-warning .text-muted {
  color: #fbf2cb !important;
}
.bg-warning .text-lt {
  color: #ffffff !important;
}
.bg-warning.auto .list-group-item,
.bg-warning .auto .list-group-item {
  border-color: #f9d21a !important;
  background-color: transparent;
}
.bg-warning.auto .list-group-item:hover,
.bg-warning .auto .list-group-item:hover,
.bg-warning.auto .list-group-item:focus,
.bg-warning .auto .list-group-item:focus,
.bg-warning.auto .list-group-item:active,
.bg-warning .auto .list-group-item:active,
.bg-warning.auto .list-group-item.active,
.bg-warning .auto .list-group-item.active {
  background-color: #fcd417 !important;
}
.bg-danger {
  background-color: #f05050;
  color: #ffffff;
}
.bg-danger.lt,
.bg-danger .lt {
  background-color: #f06a6a;
}
.bg-danger.lter,
.bg-danger .lter {
  background-color: #f18282;
}
.bg-danger.dk,
.bg-danger .dk {
  background-color: #f13636;
}
.bg-danger.dker,
.bg-danger .dker {
  background-color: #f21b1b;
}
.bg-danger.bg,
.bg-danger .bg {
  background-color: #f05050;
}
.bg-danger a {
  color: #ffffff;
}
.bg-danger a:hover {
  color: #fff;
}
.bg-danger a.list-group-item:hover,
.bg-danger a.list-group-item:focus {
  background-color: inherit;
}
.bg-danger .nav > li:hover > a,
.bg-danger .nav > li:focus > a,
.bg-danger .nav > li.active > a {
  color: #fff;
  background-color: #f13636;
}
.bg-danger .nav > li > a {
  color: #f2f2f2;
}
.bg-danger .nav > li > a:hover,
.bg-danger .nav > li > a:focus {
  background-color: #f13f3f;
}
.bg-danger .nav .open > a {
  background-color: #f13636;
}
.bg-danger .caret {
  border-top-color: #ffffff;
  border-bottom-color: #ffffff;
}
.bg-danger.navbar .nav > li.active > a {
  color: #fff;
  background-color: #f13636;
}
.bg-danger .open > a,
.bg-danger .open > a:hover,
.bg-danger .open > a:focus {
  color: #fff;
}
.bg-danger .text-muted {
  color: #e6e6e6 !important;
}
.bg-danger .text-lt {
  color: #ffffff !important;
}
.bg-danger.auto .list-group-item,
.bg-danger .auto .list-group-item {
  border-color: #ee3939 !important;
  background-color: transparent;
}
.bg-danger.auto .list-group-item:hover,
.bg-danger .auto .list-group-item:hover,
.bg-danger.auto .list-group-item:focus,
.bg-danger .auto .list-group-item:focus,
.bg-danger.auto .list-group-item:active,
.bg-danger .auto .list-group-item:active,
.bg-danger.auto .list-group-item.active,
.bg-danger .auto .list-group-item.active {
  background-color: #f13636 !important;
}
.bg-white {
  background-color: #fff;
  color: #58666e;
}
.bg-white a {
  color: inherit;
}
.bg-white a:hover {
  color: inherit;
}
.bg-white .text-muted {
  color: #98a6ad !important;
}
.bg-white .lt,
.bg-white .lter,
.bg-white .dk,
.bg-white .dker {
  background-color: #fff;
}
.bg-white-only {
  background-color: #fff;
}
.bg-white-opacity {
  background-color: rgba(255, 255, 255, 0.5);
}
.bg-black-opacity {
  background-color: rgba(32, 43, 54, 0.5);
}
a.bg-light:hover {
  color: inherit;
}
.bg-blue {
  background-color: #0747A6;
  color: #86b2f3;
}
.bg-blue.lt,
.bg-blue .lt {
  background-color: #0c5cd4;
}
.bg-blue.lter,
.bg-blue .lter {
  background-color: #1068e9;
}
.bg-blue.dk,
.bg-blue .dk {
  background-color: #033277;
}
.bg-blue.dker,
.bg-blue .dker {
  background-color: #01275f;
}
.bg-blue.bg,
.bg-blue .bg {
  background-color: #0747A6;
}
.bg-blue a {
  color: #b4cff8;
}
.bg-blue a:hover {
  color: #fff;
}
.bg-blue a.list-group-item:hover,
.bg-blue a.list-group-item:focus {
  background-color: inherit;
}
.bg-blue .nav > li:hover > a,
.bg-blue .nav > li:focus > a,
.bg-blue .nav > li.active > a {
  color: #fff;
  background-color: #043c8f;
}
.bg-blue .nav > li > a {
  color: #9dc1f5;
}
.bg-blue .nav > li > a:hover,
.bg-blue .nav > li > a:focus {
  background-color: #044099;
}
.bg-blue .nav .open > a {
  background-color: #043c8f;
}
.bg-blue .caret {
  border-top-color: #86b2f3;
  border-bottom-color: #86b2f3;
}
.bg-blue.navbar .nav > li.active > a {
  color: #fff;
  background-color: #043c8f;
}
.bg-blue .open > a,
.bg-blue .open > a:hover,
.bg-blue .open > a:focus {
  color: #fff;
}
.bg-blue .text-muted {
  color: #5894ee !important;
}
.bg-blue .text-lt {
  color: #fafcff !important;
}
.bg-blue.auto .list-group-item,
.bg-blue .auto .list-group-item {
  border-color: #063d8e !important;
  background-color: transparent;
}
.bg-blue.auto .list-group-item:hover,
.bg-blue .auto .list-group-item:hover,
.bg-blue.auto .list-group-item:focus,
.bg-blue .auto .list-group-item:focus,
.bg-blue.auto .list-group-item:active,
.bg-blue .auto .list-group-item:active,
.bg-blue.auto .list-group-item.active,
.bg-blue .auto .list-group-item.active {
  background-color: #043c8f !important;
}
.bg-blue-hover {
  background-color: rgba(9, 30, 66, 0.4);
  color: #ffffff;
}
.bg-blue-hover.lt,
.bg-blue-hover .lt {
  background-color: rgba(17, 51, 109, 0.4);
}
.bg-blue-hover.lter,
.bg-blue-hover .lter {
  background-color: rgba(22, 62, 130, 0.4);
}
.bg-blue-hover.dk,
.bg-blue-hover .dk {
  background-color: rgba(3, 10, 21, 0.4);
}
.bg-blue-hover.dker,
.bg-blue-hover .dker {
  background-color: rgba(0, 0, 0, 0.4);
}
.bg-blue-hover.bg,
.bg-blue-hover .bg {
  background-color: rgba(9, 30, 66, 0.4);
}
.bg-blue-hover a {
  color: #ffffff;
}
.bg-blue-hover a:hover {
  color: #fff;
}
.bg-blue-hover a.list-group-item:hover,
.bg-blue-hover a.list-group-item:focus {
  background-color: inherit;
}
.bg-blue-hover .nav > li:hover > a,
.bg-blue-hover .nav > li:focus > a,
.bg-blue-hover .nav > li.active > a {
  color: #fff;
  background-color: #f3f2f2;
}
.bg-blue-hover .nav > li > a {
  color: #f2f2f2;
}
.bg-blue-hover .nav > li > a:hover,
.bg-blue-hover .nav > li > a:focus {
  background-color: #f8f7f7;
}
.bg-blue-hover .nav .open > a {
  background-color: #f3f2f2;
}
.bg-blue-hover .caret {
  border-top-color: #ffffff;
  border-bottom-color: #ffffff;
}
.bg-blue-hover.navbar .nav > li.active > a {
  color: #fff;
  background-color: #f3f2f2;
}
.bg-blue-hover .open > a,
.bg-blue-hover .open > a:hover,
.bg-blue-hover .open > a:focus {
  color: #fff;
}
.bg-blue-hover .text-muted {
  color: #e6e6e6 !important;
}
.bg-blue-hover .text-lt {
  color: #ffffff !important;
}
.bg-blue-hover.auto .list-group-item,
.bg-blue-hover .auto .list-group-item {
  border-color: #f2f2f2 !important;
  background-color: transparent;
}
.bg-blue-hover.auto .list-group-item:hover,
.bg-blue-hover .auto .list-group-item:hover,
.bg-blue-hover.auto .list-group-item:focus,
.bg-blue-hover .auto .list-group-item:focus,
.bg-blue-hover.auto .list-group-item:active,
.bg-blue-hover .auto .list-group-item:active,
.bg-blue-hover.auto .list-group-item.active,
.bg-blue-hover .auto .list-group-item.active {
  background-color: #f3f2f2 !important;
}
a.bg-primary:hover {
  background-color: #6254b2;
}
a.text-primary:hover {
  color: #6254b2;
}
.text-primary {
  color: #7266ba;
}
.text-primary-lt {
  color: #8278c2;
}
.text-primary-lter {
  color: #9289ca;
}
.text-primary-dk {
  color: #6254b2;
}
.text-primary-dker {
  color: #564aa3;
}
a.bg-info:hover {
  background-color: #19a9d5;
}
a.text-info:hover {
  color: #19a9d5;
}
.text-info {
  color: #23b7e5;
}
.text-info-lt {
  color: #3abee8;
}
.text-info-lter {
  color: #51c6ea;
}
.text-info-dk {
  color: #19a9d5;
}
.text-info-dker {
  color: #1797be;
}
a.bg-success:hover {
  background-color: #23ad44;
}
a.text-success:hover {
  color: #23ad44;
}
.text-success {
  color: #27c24c;
}
.text-success-lt {
  color: #2ed556;
}
.text-success-lter {
  color: #43d967;
}
.text-success-dk {
  color: #23ad44;
}
.text-success-dker {
  color: #1e983b;
}
a.bg-warning:hover {
  background-color: #f9d21a;
}
a.text-warning:hover {
  color: #f9d21a;
}
.text-warning {
  color: #fad733;
}
.text-warning-lt {
  color: #fbdc4c;
}
.text-warning-lter {
  color: #fbe165;
}
.text-warning-dk {
  color: #f9d21a;
}
.text-warning-dker {
  color: #f4ca06;
}
a.bg-danger:hover {
  background-color: #ee3939;
}
a.text-danger:hover {
  color: #ee3939;
}
.text-danger {
  color: #f05050;
}
.text-danger-lt {
  color: #f26767;
}
.text-danger-lter {
  color: #f47f7f;
}
.text-danger-dk {
  color: #ee3939;
}
.text-danger-dker {
  color: #ec2121;
}
a.bg-dark:hover {
  background-color: #2f3342;
}
a.text-dark:hover {
  color: #2f3342;
}
.text-dark {
  color: #3a3f51;
}
.text-dark-lt {
  color: #454b60;
}
.text-dark-lter {
  color: #4f566f;
}
.text-dark-dk {
  color: #2f3342;
}
.text-dark-dker {
  color: #252833;
}
a.bg-black:hover {
  background-color: #131e25;
}
a.text-black:hover {
  color: #131e25;
}
.text-black {
  color: #1c2b36;
}
.text-black-lt {
  color: #253847;
}
.text-black-lter {
  color: #2d4658;
}
.text-black-dk {
  color: #131e25;
}
.text-black-dker {
  color: #0b1014;
}
a.bg-blue-hover:hover {
  background-color: #063d8e;
}
a.text-blue-hover:hover {
  color: #063d8e;
}
.text-blue-hover {
  color: #0747A6;
}
.text-blue-hover-lt {
  color: #0851be;
}
.text-blue-hover-lter {
  color: #095cd7;
}
.text-blue-hover-dk {
  color: #063d8e;
}
.text-blue-hover-dker {
  color: #053275;
}
.text-white {
  color: #fff;
}
.text-black {
  color: #000;
}
.text-blue {
  color: #0747A6;
}
.text-muted {
  color: #98a6ad;
}
.bg {
  background-color: #f0f3f4;
}
.pos-rlt {
  position: relative;
}
.pos-stc {
  position: static !important;
}
.pos-abt {
  position: absolute;
}
.pos-fix {
  position: fixed;
}
.show {
  visibility: visible;
}
.line {
  width: 100%;
  height: 2px;
  margin: 10px 0;
  font-size: 0;
  overflow: hidden;
}
.line-xs {
  margin: 0;
}
.line-lg {
  margin-top: 15px;
  margin-bottom: 15px;
}
.line-dashed {
  border-style: dashed !important;
  background-color: transparent;
  border-width: 0;
}
.no-line {
  border-width: 0;
}
.no-border,
.no-borders {
  border-color: transparent;
  border-width: 0;
}
.no-radius {
  border-radius: 0;
}
.block {
  display: block;
}
.block.hide {
  display: none;
}
.inline {
  display: inline-block !important;
}
.none {
  display: none;
}
.pull-none {
  float: none;
}
.rounded {
  border-radius: 500px;
}
.clear {
  display: block;
  overflow: hidden;
}
.no-bg {
  background-color: transparent;
  color: inherit;
}
.no-select {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.l-h {
  line-height: 1.42857143;
}
.l-h-0x {
  line-height: 0;
}
.l-h-1x {
  line-height: 1.2;
}
.l-h-2x {
  line-height: 2em;
}
.l-s-1x {
  letter-spacing: 1;
}
.l-s-2x {
  letter-spacing: 2;
}
.l-s-3x {
  letter-spacing: 3;
}
.font-normal {
  font-weight: normal;
}
.font-thin {
  font-weight: 300;
}
.font-bold {
  font-weight: 700;
}
.text-3x {
  font-size: 3em;
}
.text-2x {
  font-size: 2em;
}
.text-lg {
  font-size: 18px;
}
.text-md {
  font-size: 16px;
}
.text-base {
  font-size: 14px;
}
.text-sm {
  font-size: 13px;
}
.text-xs {
  font-size: 12px;
}
.text-xxs {
  text-indent: -9999px;
}
.text-ellipsis {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.text-u-c {
  text-transform: uppercase;
}
.text-l-t {
  text-decoration: line-through;
}
.text-u-l {
  text-decoration: underline;
}
.text-active,
.active > .text,
.active > .auto .text {
  display: none !important;
}
.active > .text-active,
.active > .auto .text-active {
  display: inline-block !important;
}
.box-shadow {
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.05), 0 1px 0 rgba(0, 0, 0, 0.05);
}
.box-shadow-lg {
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.05);
}
.text-shadow {
  font-size: 170px;
  text-shadow: 0 1px 0 #dee5e7, 0 2px 0 #fcfdfd, 0 5px 10px rgba(0, 0, 0, 0.125), 0 10px 20px rgba(0, 0, 0, 0.2);
}
.no-shadow {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}
.wrapper-xs {
  padding: 5px;
}
.wrapper-sm {
  padding: 10px;
}
.wrapper {
  padding: 15px;
}
.wrapper-md {
  padding: 20px;
}
.wrapper-lg {
  padding: 30px;
}
.wrapper-xl {
  padding: 50px;
}
.padder-lg {
  padding-left: 30px;
  padding-right: 30px;
}
.padder-md {
  padding-left: 20px;
  padding-right: 20px;
}
.padder {
  padding-left: 15px;
  padding-right: 15px;
}
.padder-v {
  padding-top: 15px;
  padding-bottom: 15px;
}
.no-padder {
  padding: 0 !important;
}
.pull-in {
  margin-left: -15px;
  margin-right: -15px;
}
.pull-out {
  margin: -10px -15px;
}
.b {
  border: 1px solid rgba(0, 0, 0, 0.05);
}
.b-a {
  border: 1px solid #dee5e7;
}
.b-t {
  border-top: 1px solid #dee5e7;
}
.b-r {
  border-right: 1px solid #dee5e7;
}
.b-b {
  border-bottom: 1px solid #dee5e7;
}
.b-l {
  border-left: 1px solid #dee5e7;
}
.b-light {
  border-color: #edf1f2;
}
.b-dark {
  border-color: #3a3f51;
}
.b-black {
  border-color: #3a3f51;
}
.b-primary {
  border-color: #7266ba;
}
.b-success {
  border-color: #27c24c;
}
.b-info {
  border-color: #23b7e5;
}
.b-warning {
  border-color: #fad733;
}
.b-danger {
  border-color: #f05050;
}
.b-white {
  border-color: #fff;
}
.b-dashed {
  border-style: dashed !important;
}
.b-l-light {
  border-left-color: #edf1f2;
}
.b-l-dark {
  border-left-color: #3a3f51;
}
.b-l-black {
  border-left-color: #3a3f51;
}
.b-l-primary {
  border-left-color: #7266ba;
}
.b-l-success {
  border-left-color: #27c24c;
}
.b-l-info {
  border-left-color: #23b7e5;
}
.b-l-warning {
  border-left-color: #fad733;
}
.b-l-danger {
  border-left-color: #f05050;
}
.b-l-white {
  border-left-color: #fff;
}
.b-l-2x {
  border-left-width: 2px;
}
.b-l-3x {
  border-left-width: 3px;
}
.b-l-4x {
  border-left-width: 4px;
}
.b-l-5x {
  border-left-width: 5px;
}
.b-2x {
  border-width: 2px;
}
.b-3x {
  border-width: 3px;
}
.b-4x {
  border-width: 4px;
}
.b-5x {
  border-width: 5px;
}
.r {
  border-radius: 2px 2px 2px 2px;
}
.r-2x {
  border-radius: 4px;
}
.r-3x {
  border-radius: 6px;
}
.r-l {
  border-radius: 2px 0 0 2px;
}
.r-r {
  border-radius: 0 2px 2px 0;
}
.r-t {
  border-radius: 2px 2px 0 0;
}
.r-b {
  border-radius: 0 0 2px 2px;
}
.m-xxs {
  margin: 2px 4px;
}
.m-xs {
  margin: 5px;
}
.m-sm {
  margin: 10px;
}
.m {
  margin: 15px;
}
.m-md {
  margin: 20px;
}
.m-lg {
  margin: 30px;
}
.m-xl {
  margin: 50px;
}
.m-n {
  margin: 0 !important;
}
.m-l-none {
  margin-left: 0 !important;
}
.m-l-xs {
  margin-left: 5px;
}
.m-l-sm {
  margin-left: 10px;
}
.m-l {
  margin-left: 15px;
}
.m-l-md {
  margin-left: 20px;
}
.m-l-lg {
  margin-left: 30px;
}
.m-l-xl {
  margin-left: 40px;
}
.m-l-xxl {
  margin-left: 50px;
}
.m-l-n-xxs {
  margin-left: -1px;
}
.m-l-n-xs {
  margin-left: -5px;
}
.m-l-n-sm {
  margin-left: -10px;
}
.m-l-n {
  margin-left: -15px;
}
.m-l-n-md {
  margin-left: -20px;
}
.m-l-n-lg {
  margin-left: -30px;
}
.m-l-n-xl {
  margin-left: -40px;
}
.m-l-n-xxl {
  margin-left: -50px;
}
.m-t-none {
  margin-top: 0 !important;
}
.m-t-xxs {
  margin-top: 1px;
}
.m-t-xs {
  margin-top: 5px;
}
.m-t-sm {
  margin-top: 10px;
}
.m-t {
  margin-top: 15px;
}
.m-t-md {
  margin-top: 20px;
}
.m-t-lg {
  margin-top: 30px;
}
.m-t-xl {
  margin-top: 40px;
}
.m-t-xxl {
  margin-top: 50px;
}
.m-t-n-xxs {
  margin-top: -1px;
}
.m-t-n-xs {
  margin-top: -5px;
}
.m-t-n-sm {
  margin-top: -10px;
}
.m-t-n {
  margin-top: -15px;
}
.m-t-n-md {
  margin-top: -20px;
}
.m-t-n-lg {
  margin-top: -30px;
}
.m-t-n-xl {
  margin-top: -40px;
}
.m-t-n-xxl {
  margin-top: -50px;
}
.m-r-none {
  margin-right: 0 !important;
}
.m-r-xxs {
  margin-right: 1px;
}
.m-r-xs {
  margin-right: 5px;
}
.m-r-sm {
  margin-right: 10px;
}
.m-r {
  margin-right: 15px;
}
.m-r-md {
  margin-right: 20px;
}
.m-r-lg {
  margin-right: 30px;
}
.m-r-xl {
  margin-right: 40px;
}
.m-r-xxl {
  margin-right: 50px;
}
.m-r-n-xxs {
  margin-right: -1px;
}
.m-r-n-xs {
  margin-right: -5px;
}
.m-r-n-sm {
  margin-right: -10px;
}
.m-r-n {
  margin-right: -15px;
}
.m-r-n-md {
  margin-right: -20px;
}
.m-r-n-lg {
  margin-right: -30px;
}
.m-r-n-xl {
  margin-right: -40px;
}
.m-r-n-xxl {
  margin-right: -50px;
}
.m-b-none {
  margin-bottom: 0 !important;
}
.m-b-xxs {
  margin-bottom: 1px;
}
.m-b-xs {
  margin-bottom: 5px;
}
.m-b-sm {
  margin-bottom: 10px;
}
.m-b {
  margin-bottom: 15px;
}
.m-b-md {
  margin-bottom: 20px;
}
.m-b-lg {
  margin-bottom: 30px;
}
.m-b-xl {
  margin-bottom: 40px;
}
.m-b-xxl {
  margin-bottom: 50px;
}
.m-b-n-xxs {
  margin-bottom: -1px;
}
.m-b-n-xs {
  margin-bottom: -5px;
}
.m-b-n-sm {
  margin-bottom: -10px;
}
.m-b-n {
  margin-bottom: -15px;
}
.m-b-n-md {
  margin-bottom: -20px;
}
.m-b-n-lg {
  margin-bottom: -30px;
}
.m-b-n-xl {
  margin-bottom: -40px;
}
.m-b-n-xxl {
  margin-bottom: -50px;
}
.avatar {
  position: relative;
  display: block;
  border-radius: 500px;
  white-space: nowrap;
}
.avatar img {
  border-radius: 500px;
  width: 100%;
}
.avatar i {
  position: absolute;
  left: 0;
  top: 0;
  width: 10px;
  height: 10px;
  margin: 2px;
  border-width: 2px;
  border-style: solid;
  border-radius: 100%;
}
.avatar i.right {
  left: auto;
  right: 0;
}
.avatar i.bottom {
  left: auto;
  top: auto;
  bottom: 0;
  right: 0;
}
.avatar i.left {
  top: auto;
  bottom: 0;
}
.avatar i.on {
  background-color: #27c24c;
}
.avatar i.off {
  background-color: #98a6ad;
}
.avatar i.busy {
  background-color: #f05050;
}
.avatar i.away {
  background-color: #fad733;
}
.avatar.thumb-md i {
  width: 12px;
  height: 12px;
  margin: 3px;
}
.avatar.thumb-sm i {
  margin: 1px;
}
.avatar.thumb-xs i {
  margin: 0;
}
.w-1x {
  width: 1em;
}
.w-2x {
  width: 2em;
}
.w-3x {
  width: 3em;
}
.w-xxs {
  width: 60px;
}
.w-xs {
  width: 90px;
}
.w-sm {
  width: 150px;
}
.w {
  width: 200px;
}
.w-md {
  width: 240px;
}
.w-lg {
  width: 280px;
}
.w-xl {
  width: 320px;
}
.w-xxl {
  width: 360px;
}
.w-full {
  width: 100%;
}
.w-auto {
  width: auto;
}
.h-auto {
  height: auto;
}
.h-full {
  height: 100%;
}
.thumb-xl {
  width: 128px;
  display: inline-block;
}
.thumb-lg {
  width: 96px;
  display: inline-block;
}
.thumb-md {
  width: 64px;
  display: inline-block;
}
.thumb {
  width: 50px;
  display: inline-block;
}
.thumb-sm {
  width: 40px;
  display: inline-block;
}
.thumb-xs {
  width: 34px;
  display: inline-block;
}
.thumb-xxs {
  width: 30px;
  display: inline-block;
}
.thumb-wrapper {
  padding: 2px;
  border: 1px solid #dee5e7;
}
.thumb img,
.thumb-xs img,
.thumb-sm img,
.thumb-md img,
.thumb-lg img,
.thumb-btn img {
  height: auto;
  max-width: 100%;
  vertical-align: middle;
}
.img-full {
  width: 100%;
}
.img-full img {
  width: 100%;
}
.scrollable {
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}
.scrollable.hover,
.scrollable.hover > .cell-inner {
  overflow-y: hidden !important;
}
.scrollable.hover:hover,
.scrollable.hover:focus,
.scrollable.hover:active {
  overflow: visible;
  overflow-y: auto;
}
.scrollable.hover:hover > .cell-inner,
.scrollable.hover:focus > .cell-inner,
.scrollable.hover:active > .cell-inner {
  overflow-y: auto !important;
}
.smart .scrollable,
.smart .scrollable > .cell-inner {
  overflow-y: auto !important;
}
.scroll-x,
.scroll-y {
  overflow: hidden;
  -webkit-overflow-scrolling: touch;
}
.scroll-y {
  overflow-y: auto;
}
.scroll-x {
  overflow-x: auto;
}
.hover-action {
  display: none;
}
.hover-rotate {
  -webkit-transition: all 0.2s ease-in-out 0.1s;
  transition: all 0.2s ease-in-out 0.1s;
}
.hover-anchor:hover > .hover-action,
.hover-anchor:focus > .hover-action,
.hover-anchor:active > .hover-action {
  display: inherit;
}
.hover-anchor:hover > .hover-rotate,
.hover-anchor:focus > .hover-rotate,
.hover-anchor:active > .hover-rotate {
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}
.backdrop {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1050;
}
.backdrop.fade {
  opacity: 0;
  filter: alpha(opacity=0);
}
.backdrop.in {
  opacity: 0.8;
  filter: alpha(opacity=80);
}
/*desktop*/
@media screen and (min-width: 992px) {
  .col-lg-2-4 {
    width: 20.000%;
    float: left;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .hidden-sm.show {
    display: inherit !important;
  }
  .no-m-sm {
    margin: 0 !important;
  }
}
/*phone*/
@media (max-width: 767px) {
  .w-auto-xs {
    width: auto;
  }
  .shift {
    display: none !important;
  }
  .shift.in {
    display: block !important;
  }
  .row-2 [class*="col"] {
    width: 50%;
    float: left;
  }
  .row-2 .col-0 {
    clear: none;
  }
  .row-2 li:nth-child(odd) {
    clear: left;
    margin-left: 0;
  }
  .text-center-xs {
    text-align: center;
  }
  .text-left-xs {
    text-align: left;
  }
  .text-right-xs {
    text-align: right;
  }
  .no-border-xs {
    border-width: 0;
  }
  .pull-none-xs {
    float: none !important;
  }
  .pull-right-xs {
    float: right !important;
  }
  .pull-left-xs {
    float: left !important;
  }
  .dropdown-menu.pull-none-xs {
    left: 0;
  }
  .hidden-xs.show {
    display: inherit !important;
  }
  .wrapper-lg,
  .wrapper-md {
    padding: 15px;
  }
  .padder-lg,
  .padder-md {
    padding-left: 15px;
    padding-right: 15px;
  }
  .no-m-xs {
    margin: 0 !important;
  }
}
.butterbar {
  position: relative;
  margin-bottom: -3px;
  height: 3px;
}
.butterbar .bar {
  position: absolute;
  height: 0;
  width: 100%;
  text-indent: -9999px;
  background-color: #23b7e5;
}
.butterbar .bar:before {
  content: "";
  height: 3px;
  position: absolute;
  left: 50%;
  right: 50%;
  background-color: inherit;
}
.butterbar.active {
  -webkit-animation: changebar 2.25s infinite 0.75s;
  -moz-animation: changebar 2.25s infinite 0.75s;
  animation: changebar 2.25s infinite 0.75s;
}
.butterbar.active .bar {
  -webkit-animation: changebar 2.25s infinite;
  -moz-animation: changebar 2.25s infinite;
  animation: changebar 2.25s infinite;
}
.butterbar.active .bar:before {
  -webkit-animation: movingbar 0.75s infinite;
  -moz-animation: movingbar 0.75s infinite;
  animation: movingbar 0.75s infinite;
}
/* Moving bar */
@-webkit-keyframes movingbar {
  0% {
    left: 50%;
    right: 50%;
  }
  99.9% {
    left: 0%;
    right: 0%;
  }
  100% {
    left: 50%;
    right: 50%;
  }
}
@-moz-keyframes movingbar {
  0% {
    left: 50%;
    right: 50%;
  }
  99.9% {
    left: 0%;
    right: 0%;
  }
  100% {
    left: 50%;
    right: 50%;
  }
}
@keyframes movingbar {
  0% {
    left: 50%;
    right: 50%;
  }
  99.9% {
    left: 0%;
    right: 0%;
  }
  100% {
    left: 50%;
    right: 50%;
  }
}
/* change bar */
@-webkit-keyframes changebar {
  0% {
    background-color: #23b7e5;
  }
  33.3% {
    background-color: #23b7e5;
  }
  33.33% {
    background-color: #fad733;
  }
  66.6% {
    background-color: #fad733;
  }
  66.66% {
    background-color: #7266ba;
  }
  99.9% {
    background-color: #7266ba;
  }
}
@-moz-keyframes changebar {
  0% {
    background-color: #23b7e5;
  }
  33.3% {
    background-color: #23b7e5;
  }
  33.33% {
    background-color: #fad733;
  }
  66.6% {
    background-color: #fad733;
  }
  66.66% {
    background-color: #7266ba;
  }
  99.9% {
    background-color: #7266ba;
  }
}
@keyframes changebar {
  0% {
    background-color: #23b7e5;
  }
  33.3% {
    background-color: #23b7e5;
  }
  33.33% {
    background-color: #fad733;
  }
  66.6% {
    background-color: #fad733;
  }
  66.66% {
    background-color: #7266ba;
  }
  99.9% {
    background-color: #7266ba;
  }
}
body {
  color: #000;
}
p {
  line-height: 1.56em;
}
.app-header {
  background-color: #ffffff;
  border-bottom: none;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.7);
  border-top: 0px solid #2f2e2e;
  border-bottom: 0px solid #2f2e2e;
}
.app-header-no-transparent {
  background-color: #ffffff;
  border-bottom: 1px solid #edf1f2;
}
.navbar-brand {
  line-height: 24px;
}
.navbar-brand img {
  max-height: 32px;
}
.navbar-brand span.tagline {
  color: #0D0D59;
  font-size: 14px;
  font-weight: 400;
}
.nav-menu > li {
  font-size: 16px;
  background-color: transparent;
  padding-right: 0;
  padding-left: 0;
}
.nav-menu > li:not(:last-child) {
  padding-right: 34px;
}
.nav-menu > li.active {
  background-color: transparent;
  color: #3588CC;
}
.app-content {
  margin-top: 8px;
}
.app-footer {
  bottom: none;
}
.pagination > li > a,
.pagination > li > span {
  color: #000;
}
.pagination > .active > a,
.pagination > .active > a:focus,
.pagination > .active > a:hover,
.pagination > .active > span,
.pagination > .active > span:focus,
.pagination > .active > span:hover {
  background-color: #0052CC;
  border-color: transparent;
}
.pagination > li > a:focus,
.pagination > li > a:hover,
.pagination > li > span:focus,
.pagination > li > span:hover {
  color: #7266ba;
}
.app-header-fixed {
  padding-top: 70px;
}
a.text-dark,
.text-blue {
  color: #002E5D;
}
a.text-dark:hover,
a.text-dark:focus {
  color: #0747A6;
}
.text-d-blue {
  color: #4B4B83;
}
.text-black {
  color: #000;
}
.text-white {
  color: #fff;
}
.text-left {
  text-align: left !important;
}
.text-center {
  text-align: center !important;
}
.text-right {
  text-align: right !important;
}
.text-justify {
  text-align: justify !important;
}
.bg-gdp-blue {
  background-color: #0052CC;
}
.bg-gd-green {
  background-color: #2CB89B;
}
.input-group-addon {
  padding: 0px 12px !important;
}
.panel-heading {
  text-transform: uppercase;
  letter-spacing: 0.055em;
  font-size: 13px;
}
.panel-heading ul.list-inline {
  letter-spacing: 0.035em;
}
.panel-body .sub-header {
  text-transform: uppercase;
  letter-spacing: 0.06em;
  font-size: 12px;
}
.btn {
  font-weight: 550;
}
.badge {
  font-size: 13px;
  padding: 6px 12px;
  border-radius: 4px;
}
.bg-primary,
.btn-primary {
  background-color: #0052CC;
  border: none;
}
.btn-primary:active,
.btn-primary:hover,
.btn-primary:focus {
  background-color: #0747A6;
  border: none;
}
.btn-primary.disabled {
  background-color: rgba(64, 64, 64, 0.6);
  border: none;
}
.btn-primary.disabled:active,
.btn-primary.disabled:hover,
.btn-primary.disabled:focus {
  background-color: rgba(64, 64, 64, 0.6);
  border: none;
}
.btn-info {
  background-color: #19a9d5;
  border: none;
}
.btn-info:active,
.btn-info:hover,
.btn-info:focus {
  background-color: #5bc0de;
  border: none;
}
.btn-success {
  background-color: #27c24c;
  border: none;
}
.btn-success:active,
.btn-success:hover,
.btn-success:focus {
  background-color: #23ad44;
  border: none;
}
.btn-danger {
  background-color: #f05050;
  border: none;
}
.btn-danger:active,
.btn-danger:hover,
.btn-danger:focus {
  background-color: #ee3939;
  border: none;
}
.btn-sm,
.btn-md {
  text-transform: uppercase;
  letter-spacing: 0.03em;
}
.btn-sm {
  font-size: 12px;
  padding-top: 6px;
  padding-bottom: 6px;
}
.btn-md {
  font-size: 14px;
  padding-top: 8px;
  padding-bottom: 8px;
}
.form-control:focus {
  border: 2px solid #0052CC;
}
.i-checks input:checked + i {
  border-color: #0052CC;
}
.i-checks input:checked + i:before {
  background-color: #0052CC;
}
.alert-danger {
  background-color: #a94442;
}
.alert-success {
  background-color: #3c763d;
}
.thumb-sm {
  width: 40px;
  height: 40px;
}
.thumb-sm img {
  width: 100%;
  height: 100%;
}
.thumb-lg {
  width: 96px;
  height: 96px;
}
.thumb-lg img {
  width: 100%;
  height: 100%;
}
table.table-content-mid thead tr th,
table.table-content-mid tbody tr td {
  vertical-align: middle;
}
.no-t-margin {
  margin-top: 0;
}
.no-r-margin {
  margin-right: 0;
}
.no-b-margin {
  margin-bottom: 0;
}
.no-l-margin {
  margin-left: 0;
}
.no-margin {
  margin: 0;
}
.no-v-margin {
  margin-top: 0;
  margin-bottom: 0;
}
.no-h-margin {
  margin-left: 0;
  margin-right: 0;
}
.mt-8 {
  margin-top: 8px;
}
.mt-12 {
  margin-top: 12px;
}
.mt-16 {
  margin-top: 16px;
}
.mt-20 {
  margin-top: 20px;
}
.mt-24 {
  margin-top: 24px;
}
.mt-28 {
  margin-top: 28px;
}
.mt-32 {
  margin-top: 32px;
}
.mt-40 {
  margin-top: 40px;
}
.mr-4 {
  margin-right: 4px;
}
.mr-6 {
  margin-right: 6px;
}
.mr-8 {
  margin-right: 8px;
}
.mr-12 {
  margin-right: 12px;
}
.mr-16 {
  margin-right: 16px;
}
.mb-4 {
  margin-bottom: 4px;
}
.mb-8 {
  margin-bottom: 8px;
}
.mb-12 {
  margin-bottom: 12px;
}
.mb-16 {
  margin-bottom: 16px;
}
.mb-20 {
  margin-bottom: 20px;
}
.mb-24 {
  margin-bottom: 24px;
}
.mb-32 {
  margin-bottom: 32px;
}
.mb-40 {
  margin-bottom: 40px;
}
.mb-64 {
  margin-bottom: 64px;
}
.mb-80 {
  margin-bottom: 80px;
}
.mv-8 {
  margin-top: 8px;
  margin-bottom: 8px;
}
.mv-12 {
  margin-top: 12px;
  margin-bottom: 12px;
}
.mv-16 {
  margin-top: 16px;
  margin-bottom: 16px;
}
.mv-24 {
  margin-top: 24px;
  margin-bottom: 24px;
}
.mv-32 {
  margin-top: 32px;
  margin-bottom: 32px;
}
.mv-40 {
  margin-top: 40px;
  margin-bottom: 40px;
}
.mv-48 {
  margin-top: 48px;
  margin-bottom: 48px;
}
.ml-4 {
  margin-left: 4px;
}
.ml-8 {
  margin-left: 8px;
}
.ml-12 {
  margin-left: 12px;
}
.ml-16 {
  margin-left: 16px;
}
.no-t-padding {
  padding-top: 0;
}
.no-r-padding {
  padding-right: 0;
}
.no-b-padding {
  padding-bottom: 0;
}
.no-l-padding {
  padding-left: 0;
}
.no-padding {
  padding: 0;
}
.no-v-padding {
  padding-top: 0;
  padding-bottom: 0;
}
.no-h-padding {
  padding-left: 0;
  padding-right: 0;
}
.pt-8 {
  padding-top: 8px;
}
.pt-16 {
  padding-top: 16px;
}
.pt-24 {
  padding-top: 24px;
}
.pr-8 {
  padding-right: 8px;
}
.pr-16 {
  padding-right: 16px;
}
.pb-4 {
  padding-bottom: 4px;
}
.pb-8 {
  padding-bottom: 8px;
}
.pb-16 {
  padding-bottom: 16px;
}
.pl-8 {
  padding-left: 8px;
}
.pl-16 {
  padding-left: 16px;
}
.pl-20 {
  padding-left: 20px;
}
.pl-24 {
  padding-left: 24px;
}
.pl-40 {
  padding-left: 40px;
}
.ph-8 {
  padding-left: 8px;
  padding-right: 8px;
}
.ph-16 {
  padding-left: 16px;
  padding-right: 16px;
}
.ph-40 {
  padding-left: 40px;
  padding-right: 40px;
}
.pv-8 {
  padding-top: 8px;
  padding-bottom: 8px;
}
.pv-12 {
  padding-top: 12px;
  padding-bottom: 12px;
}
.pv-16 {
  padding-top: 16px;
  padding-bottom: 16px;
}
.pv-24 {
  padding-top: 24px;
  padding-bottom: 24px;
}
.pv-32 {
  padding-top: 32px;
  padding-bottom: 32px;
}
.pv-40 {
  padding-top: 40px;
  padding-bottom: 40px;
}
.p-4 {
  padding: 4px;
}
.p-8 {
  padding: 8px;
}
.p-12 {
  padding: 12px;
}
.p-16 {
  padding: 16px;
}
.p-40 {
  padding: 40px;
}
.text-bold {
  font-weight: bold;
}
.text-italic {
  font-style: italic;
}
.text-normal {
  font-style: normal;
}
.text-justify {
  text-align: justify;
}
.fs-12 {
  font-size: 12px;
}
.fs-13 {
  font-size: 13px;
}
.fs-14 {
  font-size: 14px;
}
.fs-16 {
  font-size: 16px;
}
.fs-18 {
  font-size: 18px;
}
.fs-20 {
  font-size: 20px;
}
.fs-22 {
  font-size: 22px;
}
.fs-24 {
  font-size: 24px;
}
.fs-26 {
  font-size: 26px;
}
.fs-28 {
  font-size: 28px;
}
.fs-30 {
  font-size: 30px;
}
.fs-32 {
  font-size: 32px;
}
.fs-36 {
  font-size: 36px;
}
.fs-38 {
  font-size: 38px;
}
.fs-40 {
  font-size: 40px;
}
.fs-42 {
  font-size: 42px;
}
.fs-44 {
  font-size: 44px;
}
.fs-48 {
  font-size: 48px;
}
.fs-50 {
  font-size: 50px;
}
.fs-52 {
  font-size: 52px;
}
.fs-56 {
  font-size: 56px;
}
.fs-80 {
  font-size: 80px;
}
.fw-500 {
  font-weight: 500 !important;
}
.fw-550 {
  font-weight: 550 !important;
}
.fw-600 {
  font-weight: 600 !important;
}
.fw-700 {
  font-weight: 700 !important;
}
ul.nav-hover-no-bg li:hover,
ul.nav-hover-no-bg li:focus,
ul.nav-hover-no-bg li a:hover,
ul.nav-hover-no-bg li a:focus {
  background-color: transparent;
}
.required {
  color: red;
}
ul.list-spaces > li:not(:last-child) {
  margin-bottom: 12px;
}
.btn-full {
  width: 100%;
}
.bt-1 {
  border-top: 1px solid #dcdcdc;
}
.bb-1 {
  border-bottom: 1px solid #dcdcdc;
}
.bb-2 {
  border-bottom: 2px solid #dcdcdc;
}
.b-1 {
  border: 1px solid #dcdcdc;
}
.alert-success p,
.alert-danger p,
.alert-success span,
.alert-danger span {
  color: #fff;
}
.no-border-radius {
  border-radius: 0px;
}
.bs-material {
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12);
}
.br-2 {
  border-radius: 2px;
}
.br-3 {
  border-radius: 3px;
}
.br-4 {
  border-radius: 4px;
}
.br-6 {
  border-radius: 6px;
}
.br-8 {
  border-radius: 8px;
}
.br-10 {
  border-radius: 10px;
}
.br-12 {
  border-radius: 12px;
}
.br-14 {
  border-radius: 14px;
}
.br-15 {
  border-radius: 15px;
}
.br-16 {
  border-radius: 16px;
}
.br-18 {
  border-radius: 18px;
}
.br-20 {
  border-radius: 20px;
}
.br-30 {
  border-radius: 30px;
}
.br-25p {
  border-radius: 25%;
}
.br-50p {
  border-radius: 50%;
}
.bs-card {
  background: #fff;
  color: #000;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12);
  border-radius: 2px;
}
.upperc {
  text-transform: uppercase;
}
ul.section-nav {
  position: fixed;
  width: inherit;
  height: inherit;
  background-color: transparent;
  right: 40px;
  text-align: right;
  z-index: 9;
}
ul.section-nav > li {
  width: 16px;
  height: 16px;
  background: transparent;
  border: 2px solid #000;
  border-radius: 12px;
}
ul.section-nav > li:not(:last-child) {
  margin-bottom: 16px;
}
ul.section-nav > li.active {
  background: #000;
}
ul.section-nav > li > a {
  width: 100%;
  height: 100%;
  display: block;
}
.valign-start {
  display: flex;
  align-items: flex-start;
}
.valign-center {
  display: flex;
  align-items: center;
}
.valign-end {
  display: flex;
  align-items: flex-end;
}
.halign-start {
  display: flex;
  justify-content: flex-start;
}
.halign-center {
  display: flex;
  justify-content: center;
}
.halign-end {
  display: flex;
  justify-content: flex-end;
}
.sec-home {
  background-color: #fff;
  padding: 0;
}
.sec-home > div:nth-child(1),
.sec-home > div:nth-child(2) {
  background-repeat: no-repeat;
  background-size: cover;
  background-position-y: -110px;
  height: 700px;
  padding: 240px 0;
}
.sec-home > div:nth-child(3) {
  background-color: #ef5e5f;
  padding: 60px 0;
}
.sec-home > div:nth-child(4) {
  background-color: #0d0d59;
  padding: 60px 0;
}
.sec-home > div:nth-child(1) h3,
.sec-home > div:nth-child(2) h3 {
  font-size: 56px;
  line-height: 70px;
  font-weight: bold;
  margin-top: 0;
  margin-bottom: 0;
}
.sec-home > div:nth-child(3) h3 {
  color: #fff;
  font-size: 40px;
  font-weight: 700;
  line-height: 60px;
}
.sec-home > div:nth-child(4) h4 {
  color: #fff;
  font-size: 40px;
  font-weight: 700;
  text-align: right;
}
.sec-benefits {
  padding: 0;
}
.sec-benefits .bg-inverse-white {
  background-color: #fff;
}
.sec-benefits .bg-inverse-transparent {
  background-color: transparent;
}
.sec-clinics .row-specialist {
  background-color: #0d0d59;
  padding: 40px 0;
}
.sec-clinics .row-specialist .row-btns div:not(:last-child) {
  margin-bottom: 18px;
}
.sec-clinics .row-screens {
  padding: 40px 0;
}
.sec-rates {
  background-color: #fff;
  padding: 56px 0;
}
.sec-rates h2 {
  font-size: 40px;
  font-weight: 700;
}
.sec-rates .col-info {
  font-size: 26px;
  font-weight: 500;
}
.sec-rates .col-info:not(:last-child) {
  margin-bottom: 40px;
}
.sec-why > div:nth-child(1) {
  background-repeat: no-repeat;
  background-size: cover;
  padding: 56px 0;
}
.sec-why > div:nth-child(2) {
  background-color: #fff;
  padding: 56px 0;
}
.sec-why .row-why-you .btn::not(:last-child) {
  margin-bottom: 12px;
}
.sec-why .row-why-you .col-info {
  text-align: center;
}
.sec-why .row-why-you .col-info:not(:last-child) {
  margin-bottom: 40px;
}
.sec-why .row-why-members .col-info {
  text-align: center;
}
.sec-why .row-why-members .col-info:not(:last-child) {
  margin-bottom: 40px;
}
.sec-hiw {
  background-color: #0d0d59;
  padding: 56px 0;
}
.sec-hiw .col-info > div:nth-child(1) {
  background-color: #fff;
  min-height: 340px;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 16px;
  padding: 0 16px;
}
.sec-hiw .col-info > div:nth-child(1) > div:nth-child(2) {
  margin-bottom: 8px;
}
.sec-testimonials {
  background-repeat: no-repeat;
  background-size: cover;
  padding: 56px 0;
}
.sec-testimonials h2 {
  font-size: 56px;
  line-height: 58px;
}
.sec-testimonials .col-info:not(:last-child) {
  margin-bottom: 32px;
}
.sec-faq {
  background-color: #ffffff;
  padding-left: 0;
  padding-right: 0;
}
.sec-faq h3 {
  font-size: 48px;
  font-weight: bold;
  margin: 40px 0;
}
.sec-faq h4 {
  color: #0052CC;
}
.sec-faq ul li {
  margin-bottom: 32px;
}
.sec-faq ul li h4,
.sec-faq ul li p {
  font-size: 22px;
}
.sec-faq ul li h4 {
  font-size: 22px;
  font-weight: bold;
  line-height: 30px;
}
.sec-faq ul li p {
  font-size: 20px;
  font-style: italic;
  line-height: 28px;
}
.rounded-avatar {
  border-radius: 50%;
}
.img-grayscale {
  filter: grayscale(100%);
}
.btn-secondary {
  background-color: #3d9be9;
  color: #fff;
}
.btn-secondary:hover,
.btn-secondary:focus {
  background-color: #2b689c;
  color: #fff;
}
ul.list-point {
  list-style: circle;
}
ol.list-point > li {
  margin-bottom: 16px;
}
table.list-point {
  border-collapse: collapse;
}
table.list-point > tbody > tr > td {
  padding-bottom: 6px;
}
.packages > .col-package {
  text-align: center;
  border-radius: 2px;
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.packages > .col-package > div {
  background-color: #fff;
  padding: 40px 0;
  min-height: 353px;
  display: flex;
  justify-content: center;
  flex-direction: column;
}
footer {
  background-color: #202020;
  padding: 40px 0;
}
.icon-social {
  display: block;
  width: 30px;
  height: 30px;
  background-repeat: no-repeat;
  transition: background-position 0.3s ease;
  -moz-transition: background-position 0.3s ease;
  -webkit-transition: background-position 0.3s ease;
  -o-transition: background-position 0.3s ease;
}
.icon-social:hover {
  transition: background-position 0.3s ease;
  -moz-transition: background-position 0.3s ease;
  -webkit-transition: background-position 0.3s ease;
  -o-transition: background-position 0.3s ease;
  background-position: 0 -30px;
  text-decoration: none;
}
.box-header:after {
  content: "";
  display: block;
  bottom: 0;
  width: 50px;
  height: 2px;
  background: #3ea3b7;
  margin-top: 10px;
}
@media only screen and (min-width: 1516px) and (max-width: 1549px) {
  .sec-home > div:nth-child(1) {
    height: auto;
    background-position-x: 0;
    background-position-y: -110px;
    padding: 160px 0;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1515px) {
  .sec-home > div:nth-child(1) {
    height: auto;
    background-position-x: 0;
    background-position-y: 0;
    padding: 160px 0;
  }
}
@media only screen and (min-width: 960px) and (max-width: 1199px) {
  .sec-home > div:nth-child(2) {
    height: auto;
    background-position-x: 0;
    background-position-y: -40px;
    padding: 160px 0;
  }
  .sec-home div:nth-child(2) h3 {
    font-size: 42px;
    line-height: 52px;
    text-align: center;
  }
  .sec-benefits .bg-inverse-white {
    background-color: transparent;
  }
  .sec-benefits .bg-inverse-transparent {
    background-color: #fff;
  }
  .sec-rates .col-info {
    font-size: 26px;
    font-weight: 500;
  }
  .sec-hiw .col-info:not(:last-child) {
    margin-bottom: 32px;
    height: auto;
  }
  .sec-hiw .col-info > div:nth-child(1) {
    min-height: 340px;
    height: auto;
  }
  .sec-testimonials h2 {
    font-size: 46px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 959px) {
  .navbar-brand {
    text-align: left;
    padding-left: 0;
  }
  .sec-home > div:nth-child(2) {
    height: auto;
    background-position-x: -160px;
    background-position-y: 0;
    padding: 160px 0;
  }
  .sec-home div:nth-child(2) h3 {
    font-size: 42px;
    line-height: 52px;
    text-align: center;
  }
  .sec-benefits .bg-inverse-white {
    background-color: transparent;
  }
  .sec-benefits .bg-inverse-transparent {
    background-color: #fff;
  }
  .sec-clinics .row-btns:last-child {
    margin-top: 18px;
  }
  .sec-rates .col-info {
    font-size: 24px;
    font-weight: 500;
  }
  .sec-hiw .col-info:not(:last-child) {
    margin-bottom: 32px;
    height: auto;
  }
  .sec-hiw .col-info > div:nth-child(1) {
    min-height: 340px;
    height: auto;
  }
  .sec-testimonials h2 {
    font-size: 46px;
  }
  .sec-testimonials .col-info:not(:last-child) {
    margin-bottom: 48px;
  }
  .packages > .col-package:not(:last-child) {
    margin-right: 0;
    margin-bottom: 24px;
  }
}
@media only screen and (max-width: 599px) {
  .navbar-brand {
    text-align: left;
    padding-left: 0;
  }
  .sec-home > div:nth-child(2) {
    height: auto;
    background-position-x: -250px;
    background-position-y: 0;
    padding: 160px 0;
  }
  .sec-home div:nth-child(2) h3 {
    font-size: 42px;
    line-height: 52px;
    text-align: center;
  }
  .sec-home div:nth-child(3) h3 {
    font-size: 30px;
    line-height: 48px;
  }
  .sec-home > div:nth-child(4) h4 {
    font-size: 30px;
    line-height: 42px;
  }
  .sec-benefits .bg-inverse-white {
    background-color: transparent;
  }
  .sec-benefits .bg-inverse-transparent {
    background-color: #fff;
  }
  .sec-clinics .row-btns:last-child {
    margin-top: 18px;
  }
  .sec-hiw .col-info:not(:last-child) {
    margin-bottom: 32px;
    height: auto;
  }
  .sec-hiw .col-info > div:nth-child(1) {
    min-height: 340px;
    height: auto;
  }
  .sec-testimonials h2 {
    font-size: 48px;
  }
  .sec-testimonials .col-info {
    margin-bottom: 40px;
  }
  footer .row-company-info {
    margin-top: 24px;
  }
  .packages > .col-package:not(:last-child) {
    margin-right: 0;
    margin-bottom: 24px;
  }
  .modal-full {
    width: 100% !important;
  }
  .modal-body > .form-group > .form-group > div {
    margin-bottom: 8px;
  }
}

@media only screen and (max-width: 599px) {
    .navbar-brand {
        text-align: left;
        padding-left: 0;
    }

    .sec-home > div:nth-child(2) {
        height: auto;
        background-position-x: -250px;
        background-position-y: 0;
        padding: 160px 0;
    }
    .sec-home div:nth-child(2) h3 {
        font-size: 42px;
        line-height: 52px;
        text-align: center;
    }
    .sec-home div:nth-child(3) h3 {
        font-size: 30px;
        line-height: 48px;
    }
    .sec-home > div:nth-child(4) h4 {
        font-size: 30px;
        line-height: 42px;
    }

    .sec-benefits .bg-inverse-white {
        background-color: transparent;
    }
    .sec-benefits .bg-inverse-transparent {
        background-color: #fff;
    }

    .sec-clinics .row-btns:last-child {
        margin-top: 18px;
    }

    .sec-hiw .col-info:not(:last-child) {
        margin-bottom: 32px;
        height: auto;
    }
    .sec-hiw .col-info > div:nth-child(1) {
        min-height: 340px;
        height: auto;
    }

    .sec-testimonials h2 {
        font-size: 48px;
    }
    .sec-testimonials .col-info {
        margin-bottom: 40px;
    }

    footer .row-company-info {
        margin-top: 24px;
    }

    .packages > .col-package:not(:last-child) {
        margin-right: 0;
        margin-bottom: 24px;
    }

    .modal-full {
        width: 100% !important;
    }

    .modal-body > .form-group > .form-group > div {
        margin-bottom: 8px;
    }
}

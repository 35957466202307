@media only screen and (min-width: 960px) and (max-width: 1199px) {
    .sec-home > div:nth-child(2) {
        height: auto;
        background-position-x: 0;
        background-position-y: -40px;
        padding: 160px 0;
    }
    .sec-home div:nth-child(2) h3 {
        font-size: 42px;
        line-height: 52px;
        text-align: center;
    }

    .sec-benefits .bg-inverse-white {
        background-color: transparent;
    }
    .sec-benefits .bg-inverse-transparent {
        background-color: #fff;
    }

    .sec-rates .col-info {
        font-size: 26px;
        font-weight: 500;
    }

    .sec-hiw .col-info:not(:last-child) {
        margin-bottom: 32px;
        height: auto;
    }
    .sec-hiw .col-info > div:nth-child(1) {
        min-height: 340px;
        height: auto;
    }

    .sec-testimonials h2 {
        font-size: 46px;
    }
}

table.table-content-mid thead tr th,
table.table-content-mid tbody tr td {
    vertical-align: middle;
}

.no-t-margin {
    margin-top: 0;
}
.no-r-margin {
    margin-right: 0;
}
.no-b-margin {
    margin-bottom: 0;
}
.no-l-margin {
    margin-left: 0;
}
.no-margin {
    margin: 0;
}
.no-v-margin {
    margin-top: 0;
    margin-bottom: 0;
}
.no-h-margin {
    margin-left: 0;
    margin-right: 0;
}

.mt-8 {
    margin-top: 8px;
}
.mt-12 {
    margin-top: 12px;
}
.mt-16 {
    margin-top: 16px;
}
.mt-20 {
    margin-top: 20px;
}
.mt-24 {
    margin-top: 24px;
}
.mt-28 {
    margin-top: 28px;
}
.mt-32 {
    margin-top: 32px;
}
.mt-40 {
    margin-top: 40px;
}

.mr-4 {
    margin-right: 4px;
}
.mr-6 {
    margin-right: 6px;
}
.mr-8 {
    margin-right: 8px;
}
.mr-12 {
    margin-right: 12px;
}
.mr-16 {
    margin-right: 16px;
}

.mb-4 {
    margin-bottom: 4px;
}
.mb-8 {
    margin-bottom: 8px;
}
.mb-12 {
    margin-bottom: 12px;
}
.mb-16 {
    margin-bottom: 16px;
}
.mb-20 {
    margin-bottom: 20px;
}
.mb-24 {
    margin-bottom: 24px;
}
.mb-32 {
    margin-bottom: 32px;
}
.mb-40 {
    margin-bottom: 40px;
}
.mb-64 {
    margin-bottom: 64px;
}
.mb-80 {
    margin-bottom: 80px;
}

.mv-8 {
    margin-top: 8px;
    margin-bottom: 8px;
}
.mv-12 {
    margin-top: 12px;
    margin-bottom: 12px;
}
.mv-16 {
    margin-top: 16px;
    margin-bottom: 16px;
}
.mv-24 {
    margin-top: 24px;
    margin-bottom: 24px;
}
.mv-32 {
    margin-top: 32px;
    margin-bottom: 32px;
}
.mv-40 {
    margin-top: 40px;
    margin-bottom: 40px;
}
.mv-48 {
    margin-top: 48px;
    margin-bottom: 48px;
}

.ml-4 {
    margin-left: 4px;
}
.ml-8 {
    margin-left: 8px;
}
.ml-12 {
    margin-left: 12px;
}
.ml-16 {
    margin-left: 16px;
}


.no-t-padding {
    padding-top: 0;
}
.no-r-padding {
    padding-right: 0;
}
.no-b-padding {
    padding-bottom: 0;
}
.no-l-padding {
    padding-left: 0;
}
.no-padding {
    padding: 0;
}
.no-v-padding {
    padding-top: 0;
    padding-bottom: 0;
}
.no-h-padding {
    padding-left: 0;
    padding-right: 0;
}

.pt-8 {
    padding-top: 8px;
}
.pt-16 {
    padding-top: 16px;
}
.pt-24 {
    padding-top: 24px;
}

.pr-8 {
    padding-right: 8px;
}
.pr-16 {
    padding-right: 16px;
}

.pb-4 {
    padding-bottom: 4px;
}
.pb-8 {
    padding-bottom: 8px;
}
.pb-16 {
    padding-bottom: 16px;
}

.pl-8 {
    padding-left: 8px;
}
.pl-16 {
    padding-left: 16px;
}
.pl-20 {
    padding-left: 20px;
}
.pl-24 {
    padding-left: 24px;
}
.pl-40 {
    padding-left: 40px;
}

.ph-8 {
    padding-left: 8px;
    padding-right: 8px;
}
.ph-16 {
    padding-left: 16px;
    padding-right: 16px;
}
.ph-40 {
    padding-left: 40px;
    padding-right: 40px;
}

.pv-8 {
    padding-top: 8px;
    padding-bottom: 8px;
}
.pv-12 {
    padding-top: 12px;
    padding-bottom: 12px;
}
.pv-16 {
    padding-top: 16px;
    padding-bottom: 16px;
}
.pv-24 {
    padding-top: 24px;
    padding-bottom: 24px;
}
.pv-32 {
    padding-top: 32px;
    padding-bottom: 32px;
}
.pv-40 {
    padding-top: 40px;
    padding-bottom: 40px;
}

.p-4 {
    padding: 4px;
}
.p-8 {
    padding: 8px;
}
.p-12 {
    padding: 12px;
}
.p-16 {
    padding: 16px;
}
.p-40 {
    padding: 40px;
}

.text-bold {
    font-weight: bold;
}
.text-italic {
    font-style: italic;
}
.text-normal {
    font-style: normal;
}

.text-justify {
    text-align: justify;
}

.fs-12 {
    font-size: 12px;
}
.fs-13 {
    font-size: 13px;
}
.fs-14 {
    font-size: 14px;
}
.fs-16 {
    font-size: 16px;
}
.fs-18 {
    font-size: 18px;
}
.fs-20 {
    font-size: 20px;
}
.fs-22 {
    font-size: 22px;
}
.fs-24 {
    font-size: 24px;
}
.fs-26 {
    font-size: 26px;
}
.fs-28 {
    font-size: 28px;
}
.fs-30 {
    font-size: 30px;
}
.fs-32 {
    font-size: 32px;
}
.fs-36 {
    font-size: 36px;
}
.fs-38 {
    font-size: 38px;
}
.fs-40 {
    font-size: 40px;
}
.fs-42 {
    font-size: 42px;
}
.fs-44 {
    font-size: 44px;
}
.fs-48 {
    font-size: 48px;
}
.fs-50 {
    font-size: 50px;
}
.fs-52 {
    font-size: 52px;
}
.fs-56 {
    font-size: 56px;
}
.fs-80 {
    font-size: 80px;
}

.fw-500 {
    font-weight: 500 !important;
}
.fw-550 {
    font-weight: 550 !important;
}
.fw-600 {
    font-weight: 600 !important;
}
.fw-700 {
    font-weight: 700 !important;
}

ul.nav-hover-no-bg li:hover,
ul.nav-hover-no-bg li:focus,
ul.nav-hover-no-bg li a:hover,
ul.nav-hover-no-bg li a:focus {
    background-color: transparent;
}

.required {
    color: red;
}

ul.list-spaces > li:not(:last-child) {
    margin-bottom: 12px;
}

.btn-full {
    width: 100%;
}

.bt-1 {
    border-top: 1px solid #dcdcdc;
}

.bb-1 {
    border-bottom: 1px solid #dcdcdc;
}

.bb-2 {
    border-bottom: 2px solid #dcdcdc;
}

.b-1 {
    border: 1px solid #dcdcdc;
}

.alert-success p,
.alert-danger p,
.alert-success span,
.alert-danger span {
    color: #fff;
}

.no-border-radius {
    border-radius: 0px;
}

.bs-material {
    box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12);
}

.br-2 {
    border-radius: 2px;
}
.br-3 {
    border-radius: 3px;
}
.br-4 {
    border-radius: 4px;
}
.br-6 {
    border-radius: 6px;
}
.br-8 {
    border-radius: 8px;
}
.br-10 {
    border-radius: 10px;
}
.br-12 {
    border-radius: 12px;
}
.br-14 {
    border-radius: 14px;
}
.br-15 {
    border-radius: 15px;
}
.br-16 {
    border-radius: 16px;
}
.br-18 {
    border-radius: 18px;
}
.br-20 {
    border-radius: 20px;
}
.br-30 {
    border-radius: 30px;
}
.br-25p {
    border-radius: 25%;
}
.br-50p {
    border-radius: 50%;
}

.bs-card {
    background: #fff;
    color: #000;
    box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12);
    border-radius: 2px;
}

.upperc {
    text-transform: uppercase;
}

ul.section-nav {
    position: fixed;
    width: inherit;
    height: inherit;
    background-color: transparent;
    right: 40px;
    text-align: right;
    z-index: 9;
}
ul.section-nav > li {
    width: 16px;
    height: 16px;
    background: transparent;
    border: 2px solid #000;
    border-radius: 12px;
}
ul.section-nav > li:not(:last-child) {
    margin-bottom: 16px;
}
ul.section-nav > li.active {
    background: #000;
}
ul.section-nav > li > a {
    width: 100%;
    height: 100%;
    display: block;
}

.valign-start {
    display: flex;
    align-items: flex-start;
}
.valign-center {
    display: flex;
    align-items: center;
}
.valign-end {
    display: flex;
    align-items: flex-end;
}

.halign-start {
    display: flex;
    justify-content: flex-start;
}
.halign-center {
    display: flex;
    justify-content: center;
}
.halign-end {
    display: flex;
    justify-content: flex-end;
}

.sec-home {
    background-color: #fff;
    padding: 0;
}
.sec-home > div:nth-child(1),
.sec-home > div:nth-child(2) {
    background-repeat: no-repeat;
    background-size: cover;
    background-position-y: -110px;
    height: 700px;
    padding: 240px 0;
}
.sec-home > div:nth-child(3) {
    background-color: #ef5e5f;
    padding: 60px 0;
}
.sec-home > div:nth-child(4) {
    background-color: #0d0d59;
    padding: 60px 0;
}
.sec-home > div:nth-child(1) h3,
.sec-home > div:nth-child(2) h3 {
    font-size: 56px;
    line-height: 70px;
    font-weight: bold;
    margin-top: 0;
    margin-bottom: 0;
}
.sec-home > div:nth-child(3) h3 {
    color: #fff;
    font-size: 40px;
    font-weight: 700;
    line-height: 60px;
}
.sec-home > div:nth-child(4) h4 {
    color: #fff;
    font-size: 40px;
    font-weight: 700;
    text-align: right;
}

.sec-benefits {
    padding: 0;
}
.sec-benefits .bg-inverse-white {
    background-color: #fff;
}
.sec-benefits .bg-inverse-transparent {
    background-color: transparent;
}

.sec-clinics {

}
.sec-clinics .row-specialist {
    background-color: rgba(13, 13, 89, 1);
    padding: 40px 0;
}
.sec-clinics .row-specialist .row-btns div:not(:last-child) {
    margin-bottom: 18px;
}
.sec-clinics .row-screens {
    padding: 40px 0;
}

.sec-rates {
    background-color: #fff;
    padding: 56px 0;
}
.sec-rates h2 {
    font-size: 40px;
    font-weight: 700;
}
.sec-rates .col-info {
    font-size: 26px;
    font-weight: 500;
}
.sec-rates .col-info:not(:last-child) {
    margin-bottom: 40px;
}

.sec-why > div:nth-child(1) {
    background-repeat: no-repeat;
    background-size: cover;
    padding: 56px 0;
}
.sec-why > div:nth-child(2) {
    background-color: #fff;
    padding: 56px 0;
}
.sec-why .row-why-you .btn::not(:last-child) {
    margin-bottom: 12px;
}
.sec-why .row-why-you .col-info {
    text-align: center;
}
.sec-why .row-why-you .col-info:not(:last-child) {
    margin-bottom: 40px;
}
.sec-why .row-why-members .col-info {
    text-align: center;
}
.sec-why .row-why-members .col-info:not(:last-child) {
    margin-bottom: 40px;
}

.sec-hiw {
    background-color: rgba(13, 13, 89, 1);
    padding: 56px 0;
}
.sec-hiw .col-info {

}
.sec-hiw .col-info > div:nth-child(1) {
    background-color: #fff;
    min-height: 340px;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 16px;
    padding: 0 16px;
}
.sec-hiw .col-info > div:nth-child(1) > div:nth-child(2) {
    margin-bottom: 8px;
}

.sec-testimonials {
    background-repeat: no-repeat;
    background-size: cover;
    padding: 56px 0;
}
.sec-testimonials h2 {
    font-size: 56px;
    line-height: 58px;
}
.sec-testimonials .col-info:not(:last-child) {
    margin-bottom: 32px;
}

.sec-faq {
    // background-color: rgba(197, 255, 255, 1);
    background-color: rgba(255, 255, 255, 1);
    padding-left: 0;
    padding-right: 0;
}
.sec-faq h3 {
    font-size: 48px;
    font-weight: bold;
    margin: 40px 0;
}
.sec-faq h4 {
    color: #0052CC;
}
.sec-faq ul li {
    margin-bottom: 32px;
}
.sec-faq ul li h4,
.sec-faq ul li p {
    font-size: 22px;
}
.sec-faq ul li h4 {
    font-size: 22px;
    font-weight: bold;
    line-height: 30px;
}
.sec-faq ul li p {
    font-size: 20px;
    font-style: italic;
    line-height: 28px;
}

.rounded-avatar {
    border-radius: 50%;
}
.img-grayscale {
    filter: grayscale(100%);
}

.btn-secondary {
    background-color: rgba(61, 155, 233, 1);
    color: #fff;
}
.btn-secondary:hover,
.btn-secondary:focus {
    background-color: rgba(43, 104, 156, 1);
    color: #fff;
}

ul.list-point {
    list-style: circle;
}

ol.list-point > li {
    margin-bottom: 16px;
}

table.list-point {
    border-collapse: collapse;
}
table.list-point > tbody > tr > td {
    padding-bottom: 6px;
}

.packages > .col-package {
    text-align: center;
    border-radius: 2px;
    display: flex;
    justify-content: center;
    flex-direction: column;
}
.packages > .col-package > div {
    background-color: #fff;
    padding: 40px 0;
    min-height: 353px;
    display: flex;
    justify-content: center;
    flex-direction: column;
}

footer {
    background-color: #202020;
    padding: 40px 0;
}

.icon-social {
	display: block;
	width: 30px;
	height: 30px;
	background-repeat: no-repeat;
	transition: background-position .3s ease;
	-moz-transition: background-position .3s ease;
	-webkit-transition: background-position .3s ease;
	-o-transition: background-position .3s ease;
}
.icon-social:hover {
	transition: background-position .3s ease;
	-moz-transition: background-position .3s ease;
	-webkit-transition: background-position .3s ease;
	-o-transition: background-position .3s ease;
	background-position: 0 -30px;
	text-decoration: none;
}

.box-header:after {
	content: "";
	display: block;
	bottom: 0;
	width: 50px;
	height: 2px;
	background: #3ea3b7;
	margin-top: 10px;
}
